import { TApiResponse, TEmailDto, TPoolDto } from 'app-platform-shared-types'
import { clientFetch } from './client-fetch'

export const getAdminPools = async (): Promise<Array<TPoolDto>> => {
  const result = await clientFetch<TPoolDto>(`/admin/pools`, {
    method: 'get',
  })
  return result?.data ?? []
}

export const getAdminPoolEmails = async (
  poolId: string,
  skip?: number,
  take?: number,
): Promise<TApiResponse<TEmailDto> | undefined> => {
  const result = await clientFetch<TEmailDto>(`/admin/pools/${poolId}/emails?skip=${skip}&take=${take}`, {
    method: 'get',
  })
  return result
}

export const postAdminResendEmail = async (
  poolId: string,
  emailId: string,
): Promise<TApiResponse<TEmailDto> | undefined> => {
  const result = await clientFetch<TEmailDto>(`/admin/pools/${poolId}/email/send/${emailId}`, {
    method: 'post',
  })
  return result
}

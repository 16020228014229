import ContactForm from './ContactForm'

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  return (
    <>
      <div className="relative flex min-h-full justify-center md:px-12 lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            <ContactForm />
          </div>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1 ">
          <div
            className="absolute inset-0 h-full w-full object-cover 
            md:bg-gradient-to-br  md:from-midtone  md:to-highlight
            lg:bg-gradient-to-r lg:from-midtone  lg:to-highlight"
          ></div>
        </div>
      </div>
    </>
  )
}

export default Contact

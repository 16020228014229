import { TPoolMemberDto } from 'app-platform-shared-types'
import { Avatar } from '../../../components'
import clsx from 'clsx'

interface PoolMemberCardProps {
  poolMember: TPoolMemberDto
  hideBalance?: boolean | undefined
}

const PoolMemberCard: React.FC<PoolMemberCardProps> = ({ poolMember, hideBalance }: PoolMemberCardProps) => {
  return (
    <>
      <div className={clsx('w-full flex flex-wrap p-1 select-none overflow-hidden')}>
        <div
          className={clsx('p-1 border rounded-md w-full flex justify-between items-center bg-highlight text-shadow')}
        >
          <Avatar
            className="border border-gray-500 bg-white h-12 w-12 rounded-full"
            imageUrl={poolMember.imgUrl ?? `https://robohash.org/${poolMember.id}.png`}
            name={poolMember.displayName}
          />
          <div className="grow flex flex-col justify-center items-center">
            <div className="text-shadow text-center">{`${poolMember.displayName}`}</div>
          </div>
          {hideBalance ||
            (poolMember.balance !== undefined && (
              <div
                className={clsx(
                  'text-lg text-right w-12',
                  poolMember?.balance && poolMember.balance <= 0 ? 'text-red-700' : 'text-shadow',
                )}
              >
                {'$' + `${poolMember.balance}`}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default PoolMemberCard

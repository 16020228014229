import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const RoutesTokenInterceptor = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const error = queryParams.get('error')

    // console.log(token)
    // console.log(error)
    if (error) {
      navigate('/401', { state: { error: `${error}` } })
      return
    }

    const token = queryParams.get('authUser')

    if (token) {
      localStorage.setItem('authUser', token)
      queryParams.delete('authUser')
    }

    const callbackUrl = queryParams.get('callbackUrl')
    queryParams.delete('callbackUrl')

    const navigateUrl = callbackUrl?.length ? callbackUrl : '/'

    window.location.href = navigateUrl
  }, [location.search, navigate])

  return null
}

export default RoutesTokenInterceptor

import axios, { AxiosRequestConfig } from 'axios'
import { ClientConfig } from '../../config/client.config'
import { TApiResponse } from 'app-platform-shared-types'
import { getRecaptchaRespToken, resetRecaptcha } from '../helpers/auth.helper'

export async function clientFetch<T>(url: string, config: AxiosRequestConfig): Promise<TApiResponse<T> | undefined> {
  config = {
    ...config,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  if (config.method?.toLocaleLowerCase().trim() !== 'get') {
    // RECAPTCHA
    try {
      const respToken = await getRecaptchaRespToken(window, config.method ?? 'unknown')
      if (respToken?.length > 0) {
        // console.log("Successful Recaptcha token retrieval")
        config.headers = {
          ...config.headers,
          grecaptcha: respToken,
        }
      } else {
        console.log('Failed Recaptcha token retrieval')
      }
    } catch (err: any) {
      var message = err?.response?.data?.message ?? err?.message ?? 'client-fetch: unknown error'
      console.error(message)
      alert('An unknown error ocurred calculating recaptcha.')
    } finally {
      resetRecaptcha(window)
    }
  }

  const currrAxios = axios.create(config)
  currrAxios.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        if (error.response.status === 401) {
          // Redirect to login page or perform any other action
          // window.location.href = `/login?returnUrl=${currUrl}`
          window.location.href = `/login?callbackUrl=${encodeURI(window.location.href)}`
        } else if (error.response.status === 403) {
          window.location.href = `/403`
        }
      }
      return Promise.reject(`Client Fetch Failes: ${JSON.stringify(error.response?.data)}`)
    },
  )

  try {
    const rawResult = await currrAxios(`${ClientConfig.publicApiBaseUrl}${url}`, config)
    return rawResult?.data?.result
  } catch (err: any) {
    var rawResultMsg = err?.response?.data?.message ?? err?.message ?? 'client-fetch currrAxios: unknown error'
    console.error(rawResultMsg)
    // alert('An unknown error ocurred while making a client side fetch.')
    throw err
  }
}

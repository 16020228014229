import {
  TPoolDto,
  TPoolMemberDto,
  TPoolActivityDto,
  TApiResponse,
  TEmailDto,
  TPoolActivityEmailCreateMeta,
  TPoolUpsertForm,
} from 'app-platform-shared-types'
import { clientFetch } from './client-fetch'

export const getPool = async (poolId: string): Promise<TPoolDto | undefined> => {
  const result = await clientFetch<TPoolDto>(`/pools/${poolId}`, {
    method: 'get',
  })
  return result?.data?.length ? result.data[0] : undefined
}

export const deletePool = async (poolId: string): Promise<void> => {
  await clientFetch<TPoolDto>(`/pools/${poolId}`, {
    method: 'delete',
  })
}

export const getPools = async (): Promise<Array<TPoolDto>> => {
  const result = await clientFetch<TPoolDto>(`/pools`, {
    method: 'get',
  })
  return result?.data ?? []
}

export const getPoolMembers = async (poolId: string): Promise<Array<TPoolMemberDto>> => {
  const result = await clientFetch<TPoolMemberDto>(`/pools/${poolId}/members`, { method: 'get' })
  return result?.data ?? []
}

export const getPoolActivity = async (poolId: string, activityId: string): Promise<TPoolActivityDto | undefined> => {
  const result = await clientFetch<TPoolActivityDto>(`/pools/${poolId}/activities/${activityId}`, { method: 'get' })
  return result?.data?.length ? result.data[0] : undefined
}

export const getPoolActivities = async (
  poolId: string,
  skip: number,
  take: number,
): Promise<TApiResponse<TPoolActivityDto> | undefined> => {
  const results = await clientFetch<TPoolActivityDto>(`/pools/${poolId}/activities?skip=${skip}&take=${take}`, {
    method: 'get',
  })

  return results
}

export const upsertPool = async (pool: TPoolUpsertForm): Promise<TPoolDto | undefined> => {
  const result = await clientFetch<TPoolDto>(`/pools/${pool.poolId}`, {
    method: 'post',
    data: JSON.stringify(pool),
  })
  return result?.data?.length ? result.data[0] : undefined
}

export const upsertPoolMember = async (poolMember: TPoolMemberDto): Promise<TPoolMemberDto> => {
  const result = await clientFetch<TPoolMemberDto>(`/pools/${poolMember.poolId}/member`, {
    method: 'post',
    data: JSON.stringify(poolMember),
  })
  if (!result?.data?.length) {
    throw new Error('Error upserting pool member')
  }

  return result.data[0]
}

export const addOrEditPoolActivity = async (
  poolId: string,
  activityId: string,
  stringifiedBody: string,
): Promise<TPoolActivityDto | undefined> => {
  const result = await clientFetch<TPoolActivityDto>(`/pools/${poolId}/activities/${activityId}`, {
    method: 'post',
    data: stringifiedBody,
  })
  return result?.data?.length ? result.data[0] : undefined
}

export const getPoolActivityEmails = async (
  poolId: string,
  activityId: string,
  skip?: number,
  take?: number,
): Promise<TApiResponse<TEmailDto> | undefined> => {
  let url = `/pools/${poolId}/activities/${activityId}/emails`
  if (skip && take) {
    url += `?skip=${skip}&take=${take}`
  }
  const results = await clientFetch<TEmailDto>(url, {
    method: 'get',
  })

  return results
}

export const createPoolActivityEmails = async (
  poolId: string,
  activityId: string,
  emails: Array<TPoolActivityEmailCreateMeta>,
): Promise<Array<TEmailDto>> => {
  const result = await clientFetch<TEmailDto>(`/pools/${poolId}/activities/${activityId}/createEmail`, {
    method: 'post',
    data: JSON.stringify(emails),
  })

  return result?.data ?? []
}

export const sendPoolActivityEmail = async (poolId: string, emailId: string): Promise<TEmailDto | undefined> => {
  const result = await clientFetch<TEmailDto>(`/emails/activity/${poolId}/${emailId}`, {
    method: 'post',
  })

  return result?.data?.length ? result.data[0] : undefined
}

export const markSuccessPoolActivityEmail = async (
  poolId: string,
  emailIds: Array<string>,
): Promise<TApiResponse<TEmailDto> | undefined> => {
  const result = await clientFetch<TEmailDto>(`/emails/activity/${poolId}/email/success`, {
    method: 'post',
    data: JSON.stringify(emailIds),
  })

  return result
}

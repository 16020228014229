export const i18n = {
  defaultLocale: 'en',
  locales: ['en', 'fr', 'ru', 'zh'],
} as const

export type Locale = (typeof i18n)['locales'][number]

export const defaultLanguage = i18n.defaultLocale

export type TSupportedLangType = {
  code: Locale
  label: string
}

export const supportedLanguages: Array<TSupportedLangType> = [
  { code: 'en', label: 'en' },
  { code: 'fr', label: 'fr' },
  { code: 'ru', label: 'ру' },
  { code: 'zh', label: '中文' },
]

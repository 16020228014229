import { TPoolUpsertForm, TPoolDto, TPoolMemberDto } from 'app-platform-shared-types'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Button, Dialog, TextField } from '../../../../components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PoolMemberCard from './PoolMemberCard'

import { RxPlusCircled } from 'react-icons/rx'
import { upsertPool, upsertPoolMember } from '../../../../lib/api/pool-fetch'
import { NIL as EMPTY_GUID } from 'uuid'
import { FaPencil, FaPlus, FaTrash } from 'react-icons/fa6'
import clsx from 'clsx'

interface PoolUpsertFormProps {
  pool?: TPoolDto | undefined
  onCancel: () => void
  setPoolLoading: Dispatch<SetStateAction<boolean>>
  onSubmit?: (user: TPoolDto | undefined) => void
}

const PoolUpsertForm: React.FC<PoolUpsertFormProps> = (props: PoolUpsertFormProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const navigate = useNavigate()

  const [showCurrPoolMemberDialog, setShowCurrPoolMemberDialog] = useState<boolean>(false)
  const [currPoolMember, setCurrPoolMember] = useState<TPoolMemberDto | undefined>()
  const [currActivePoolMembers, setCurrActivePoolMembers] = useState<Array<TPoolMemberDto>>([])
  const [currInactivePoolMembers, setCurrInactivePoolMembers] = useState<Array<TPoolMemberDto>>([])

  useEffect(() => {
    if (props.pool && !props.pool.isPoolOwner) {
      navigate('/401')
    } else if (props.pool) {
      const currPms = props.pool ? props.pool.poolMembers.filter(pm => !pm.isRemoved) : new Array<TPoolMemberDto>()
      setCurrActivePoolMembers([...currPms])

      const removedPms = props.pool ? props.pool.poolMembers.filter(pm => pm.isRemoved) : new Array<TPoolMemberDto>()
      setCurrInactivePoolMembers([...removedPms])
    }
  }, [navigate, props.pool])

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    clearErrors,
    formState: { errors },
  } = useForm<TPoolUpsertForm>()

  const handleSubmitPool: SubmitHandler<TPoolUpsertForm> = async (pool: TPoolUpsertForm) => {
    const formData = {
      poolId: props.pool?.id ?? EMPTY_GUID,
      poolDisplayName: pool.poolDisplayName,
      poolMembers: [...currActivePoolMembers, ...currInactivePoolMembers],
    } as TPoolUpsertForm

    props.setPoolLoading(true)
    await upsertPool(formData).then(
      updatedPool => {
        console.log('PoolUpsertForm: pool', updatedPool)
        props.setPoolLoading(false)
        formRef.current?.reset()
        clearErrors()

        props.onSubmit && props.onSubmit(updatedPool)

        navigate(-1)
      },
      err => {
        console.error('Error while updating PoolUpsertForm', err)
        alert(t('pool.errorPoolUpsert'))
        props.setPoolLoading(false)
        throw new Error(err)
      },
    )
  }

  const togglePoolMember = (poolMemberId: string) => {
    // console.log('Item Selector toggledItem:', id)

    const current = props.pool?.poolMembers.find(pm => pm.id === poolMemberId)
    if (!current) {
      throw new Error('PoolUpsertForm: Unexpected error, member must be present')
    }
    current.isRemoved = !current.isRemoved

    const isCurrentActiveIndex = currActivePoolMembers.findIndex(pm => pm.id === poolMemberId)
    if (isCurrentActiveIndex !== -1) {
      // move from active to inactive
      const splicedPm = currActivePoolMembers.splice(isCurrentActiveIndex, 1)
      currInactivePoolMembers.push(splicedPm[0])
    } else {
      const isCurrentInactiveIndex = currInactivePoolMembers.findIndex(pm => pm.id === poolMemberId)
      if (isCurrentInactiveIndex !== -1) {
        // move from inactive to active
        const splicedPm = currInactivePoolMembers.splice(isCurrentInactiveIndex, 1)
        currActivePoolMembers.push(splicedPm[0])
      }
    }

    setCurrActivePoolMembers([...currActivePoolMembers])
    setCurrInactivePoolMembers([...currInactivePoolMembers])
  }

  const poolMemberUpdate = async (poolMember: TPoolMemberDto) => {
    props.setPoolLoading(true)
    setShowCurrPoolMemberDialog(false)
    const isCreate = poolMember.id === EMPTY_GUID
    await upsertPoolMember(poolMember).then(updtPoolMember => {
      if (isCreate) {
        props.pool?.poolMembers.push(updtPoolMember)
        currActivePoolMembers.push(updtPoolMember)
      } else {
        const currPmIndex = currActivePoolMembers.findIndex(cap => cap.id === updtPoolMember.id)
        if (currPmIndex === -1) {
          throw new Error('Unexpected result. ')
        }
        currActivePoolMembers.splice(currPmIndex, 1, updtPoolMember)
      }

      setCurrActivePoolMembers([...currActivePoolMembers])
      setCurrPoolMember(undefined)

      props.setPoolLoading(false)
    })
  }

  // const { ref, ...registerNameVals } = { ...register('name', { required: true }) } //removed ref from register values
  register('poolDisplayName', { required: true, value: props.pool?.name })

  const upsertDialog = (poolMember: TPoolMemberDto | undefined) => {
    console.log('PoolUpsertForm: poolMember', poolMember)
    if (!poolMember) return null

    return (
      <Dialog
        isOpen={poolMember !== undefined && showCurrPoolMemberDialog}
        actionButtonLabel={t('common.save')}
        actionButtonClick={() => {
          if (!poolMember.alternativeDisplayName?.length) {
            alert(t('pool.members.displayNameRequired'))
            return
          }

          poolMemberUpdate(poolMember)
        }}
        cancelButtonClick={() => setShowCurrPoolMemberDialog(false)}
      >
        <div className="flex flex-col items-center gap-2 w-[275px]">
          <div className="text-xl font-bold border-b mt-2">
            {poolMember?.id === EMPTY_GUID ? t('pool.members.createPoolMember') : t('pool.members.editPoolMember')}
          </div>
          <TextField
            name="alternativeDisplayName"
            placeholder={t('pool.members.alternativeDisplayName')}
            label={t('pool.members.displayName')}
            defaultValue={currPoolMember?.alternativeDisplayName}
            onChange={val => setCurrPoolMember({ ...currPoolMember, alternativeDisplayName: val } as TPoolMemberDto)}
          ></TextField>
          <TextField
            name="alternativeImgUrl"
            placeholder={t('pool.members.alternativeImgUrl')}
            label={t('pool.members.imgUrl')}
            defaultValue={currPoolMember?.alternativeImgUrl}
            onChange={val => setCurrPoolMember({ ...currPoolMember, alternativeImgUrl: val } as TPoolMemberDto)}
          ></TextField>
          <TextField
            name="invitationEmail"
            placeholder={t('pool.members.invitationEmailPlaceholder')}
            label={t('pool.members.invitationEmail')}
            defaultValue={currPoolMember?.invitationEmail}
            onChange={val => setCurrPoolMember({ ...currPoolMember, invitationEmail: val } as TPoolMemberDto)}
          ></TextField>
        </div>
      </Dialog>
    )
  }

  return (
    <>
      {showCurrPoolMemberDialog && <div className="w-full">{upsertDialog(currPoolMember)}</div>}
      <form ref={formRef} onSubmit={handleSubmit(handleSubmitPool)}>
        <TextField
          defaultValue={props.pool?.name}
          type="text"
          label={t('pool.name')}
          autoComplete="name"
          onChange={(val: string) => {
            setFormValue('poolDisplayName', val)
          }}
          isInvalid={errors.poolDisplayName ? true : false}
          errorMessage={errors.poolDisplayName ? t('pool.titleReq') : undefined}
        />

        <div className="mt-3 flex justify-between">
          <div className="text-sm font-bold">{t('pool.poolMembersCurrent')}</div>
          <div>
            <Button
              theme="primary"
              className="justify-center items-center rounded-full p-1 text-xl"
              onPress={() => {
                setCurrPoolMember({
                  id: EMPTY_GUID,
                  poolId: props.pool?.id ?? EMPTY_GUID,
                } as TPoolMemberDto)
                setShowCurrPoolMemberDialog(true)
              }}
            >
              <FaPlus />
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          {currActivePoolMembers.map(pm => (
            <span key={pm.id} className="w-[175px] relative m-2">
              <PoolMemberCard poolMember={pm} hideBalance={false} />
              <div className="flex flex-row justify-between px-2">
                <div
                  className="text-gray-500 bg-white rounded-full text-sm flex flex-nowrap items-center gap-1 cursor-pointer"
                  onClick={() => togglePoolMember(pm.id)}
                >
                  <FaTrash />
                  <span>{t('common.remove')}</span>
                </div>
                <div
                  className={clsx(
                    'text-gray-500 bg-white rounded-full text-sm  flex flex-nowrap items-center gap-1 ',
                    pm.userId?.length ? 'text-gray-300 cursor-not-allowed' : 'cursor-pointer',
                  )}
                  onClick={() => {
                    if (!pm.userId) {
                      setCurrPoolMember(pm)
                      setShowCurrPoolMemberDialog(true)
                    }
                  }}
                >
                  <FaPencil />
                  <span>{t('common.edit')}</span>
                </div>
              </div>
            </span>
          ))}
        </div>

        {currInactivePoolMembers?.length > 0 && (
          <>
            <div className="mt-3 text-sm font-bold">{t('pool.poolMembersRemoved')}</div>
            <div className="flex flex-wrap justify-center gap-1">
              {currInactivePoolMembers.map(pm => (
                <span key={pm.id} className="w-[175px] relative cursor-pointer" onClick={() => togglePoolMember(pm.id)}>
                  <div className="absolute -top-1 -right-1 text-green-800 bg-white rounded-full text-2xl">
                    <RxPlusCircled />
                  </div>
                  <PoolMemberCard poolMember={pm} hideBalance={false} />
                </span>
              ))}
            </div>
          </>
        )}
        <div className="flex justify-end mt-6">
          <Button theme="neutral" onPress={() => props.onCancel()} className="w-[15%] p-1 m-1 flex justify-center">
            <span>{t('common.cancel')}</span>
          </Button>
          <Button theme="primary" type="submit" className="w-[15%] p-1 m-1 flex justify-center">
            <span>{t('common.save')}</span>
          </Button>
        </div>
      </form>
    </>
  )
}

export default PoolUpsertForm

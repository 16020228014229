import { TPoolDto } from 'app-platform-shared-types'
import { Avatar } from '../../../components'
import clsx from 'clsx'
import { tv, type VariantProps } from 'tailwind-variants'
import { useTranslation } from 'react-i18next'

const poolCardTV = tv({
  base: 'flex flex-col gap-2 border border-highlight rounded p-1',
  variants: {
    size: {
      sm: 'w-[225px]',
      md: 'w-[275px]',
      lg: 'w-full',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type PoolCardVariants = VariantProps<typeof poolCardTV>

interface PoolCardProps extends PoolCardVariants {
  pool: TPoolDto
}

const PoolCard: React.FC<PoolCardProps> = (props: PoolCardProps) => {
  const { pool } = props
  const showBalance = pool.balance !== undefined
  const { t } = useTranslation()

  return (
    <>
      <div className={poolCardTV(props)}>
        <div className="text-center font-bold ">{pool.name}</div>
        <div className="flex flex-row justify-center gap-1  py-1 ">
          <Avatar imageUrl={pool.ownerImgUrl} className="h-6 w-6 rounded-full"></Avatar>
          <span>{pool.ownerDisplayName}</span>
        </div>
        <div
          className={clsx(
            'flex flex-row border-t border-t-shadow',
            showBalance ? 'justify-between' : '',
            !showBalance ? 'justify-center' : '',
          )}
        >
          <div>{`${t('pool.members.label')}: ${pool.poolMembers?.length ?? 0}`}</div>
          {showBalance && <div>{`${t('pool.balance')}: $${pool.balance}`}</div>}
        </div>
      </div>
    </>
  )
}

export default PoolCard

import { TApiResponse, TUserDto } from 'app-platform-shared-types'
import { clientFetch } from './client-fetch'

export const getUsers = async (skip: number, take: number): Promise<TApiResponse<TUserDto> | undefined> => {
  const result = await clientFetch<TUserDto>(`/admin/users?skip=${skip}&take=${take}`, {
    method: 'get',
  })

  return result
}

export const postUser = async (user: TUserDto): Promise<TApiResponse<TUserDto> | undefined> => {
  const result = await clientFetch<TUserDto>('/admin/users', {
    method: 'post',
    data: JSON.stringify(user),
  })

  return result
}

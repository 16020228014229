import { TFlattenedTxRow } from 'app-platform-shared-types'
import { DateTime } from 'luxon'
import { formatCurrency } from '../../../../../lib/helpers/utility.helper'

interface ActivityTransactionRowProps {
  row: TFlattenedTxRow
}

const ActivityTransactionRow: React.FC<ActivityTransactionRowProps> = ({ row }: ActivityTransactionRowProps) => {
  return (
    <div className="w-full grid grid-cols-[1.5fr_2fr_1fr_1fr_1fr] p-2 gap-2 text-shadow">
      <div>{row.memberDisplayName}</div>
      <div>{DateTime.fromJSDate(row.date).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</div>
      <div>{formatCurrency(row.initialBal)}</div>
      <div>{formatCurrency(row.creditDebitAmt)}</div>
      <div>{formatCurrency(row.endBal)}</div>
    </div>
  )
}

export default ActivityTransactionRow

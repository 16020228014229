'use client'

import { ClientConfig } from '../config/client.config'
import { useEffect } from 'react'

interface RecaptchaProps {}

const Recaptcha: React.FC<RecaptchaProps> = () => {
  // RECAPTCHA
  let myWindow: any

  const handleRecaptchaLoaded = () => {
    myWindow?.grecaptcha.ready(async () => {
      console.info('AppPlatform: Google Recaptcha Loaded')
    })
  }

  useEffect(() => {
    // Add reCaptcha
    // eslint-disable-next-line react-hooks/exhaustive-deps
    myWindow = window as any
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${ClientConfig.publicRecaptchaSiteKey}`
    script.addEventListener('load', handleRecaptchaLoaded)
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <div className="g-recaptcha" data-sitekey={ClientConfig.publicRecaptchaSiteKey} data-size="invisible"></div>
    </>
  )
}

export default Recaptcha

import { VariantProps, tv } from 'tailwind-variants'
import { useIsClient } from '../../lib/hooks'
import { useEffect, useState } from 'react'

const avatarTV = tv({
  base: 'overflow-hidden',
  variants: {
    size: {
      xs: 'w-[30px] h-[30px]',
      sm: 'w-[50px] h-[50px]',
      md: 'w-[100px] h-[100px]',
      lg: 'w-[150px] h-[150px]',
      xl: 'w-[200px] h-[200px]',
    },
    rounded: {
      full: 'rounded-full',
      base: 'rounded',
      none: 'rounded-none',
    },
  },
  defaultVariants: {
    size: 'md',
    rounded: 'full',
  },
})

type AvatarVariants = VariantProps<typeof avatarTV>

interface AvatarProps extends AvatarVariants {
  className: string
  imageUrl?: string
  altText?: string
  name?: string
  role?: string
  altTitle?: string
}

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { imageUrl, altText, name, role, size } = props
  const altTitle = altText || name || ''
  const isClient = useIsClient()
  const [loggedInUserImgUrl, setLoggedInUserImgUrl] = useState<string | undefined>()

  useEffect(() => {
    if (isClient) {
      setLoggedInUserImgUrl(imageUrl)
    }
  }, [isClient, imageUrl])

  let imgDims: { width: number; height: number }
  switch (size) {
    case 'xs':
      imgDims = { width: 30, height: 30 }
      break
    case 'sm':
      imgDims = { width: 50, height: 50 }
      break
    case 'md':
      imgDims = { width: 100, height: 100 }
      break
    case 'lg':
      imgDims = { width: 150, height: 150 }
      break
    case 'xl':
      imgDims = { width: 200, height: 200 }
      break

    default:
      imgDims = { width: 150, height: 150 }
      break
  }
  return (
    <>
      <div className={avatarTV(props)} role={role} aria-label={name} title={altTitle}>
        {imageUrl ? (
          <img
            width={imgDims.width}
            height={imgDims.height}
            src={loggedInUserImgUrl}
            alt={altTitle}
            className="h-full w-full object-cover"
          />
        ) : (
          <span>{name && name.charAt(0).toUpperCase()}</span>
        )}
      </div>
    </>
  )
}

export default Avatar

import { useNavigate } from 'react-router-dom'
import { Button } from '../../components'
import { getAuth } from '../../lib/helpers/auth.helper'
import { useEffect } from 'react'

interface AdminProps {}

const Admin: React.FC<AdminProps> = () => {
  const navigate = useNavigate()
  const authUser = getAuth()

  useEffect(() => {
    const authUser = getAuth()
    if (!authUser || !authUser.isAdmin) {
      navigate('/', { replace: true })
    }
  }, [navigate])

  if (!authUser || !authUser.isAdmin) {
    return null
  }

  return (
    <>
      {authUser?.isAdmin && (
        <div className="flex justify-between">
          <div className="flex">
            <Button className="p-2 m-2 min-w-10" theme="secondary" onPress={() => navigate('/users/admin')}>
              Users
            </Button>
            <Button className="p-2 m-2 min-w-10" theme="secondary" onPress={() => navigate('/pools/admin')}>
              Pools
            </Button>
          </div>
          <Button className="p-2 m-2 min-w-10" theme="secondary" onPress={() => navigate(-1)}>
            Back
          </Button>
        </div>
      )}
    </>
  )
}

export default Admin

export class ClientConfig {
  public static get publicRecaptchaEnabled(): boolean {
    return process.env.REACT_APP_RECAPTCHA_ENABLED === 'true'
  }
  public static get publicRecaptchaSiteKey(): string {
    return process.env.REACT_APP_RECAPTCHA_KEY ?? ''
  }
  public static get publicApiBaseUrl(): string {
    return process.env.REACT_APP_API_BASE_URL ?? ''
  }
  public static get publicAuthBaseUrl(): string {
    return process.env.REACT_APP_AUTH_BASE_URL ?? ''
  }
  public static get publicCmsUrl(): string {
    return process.env.REACT_APP_PUBLIC_CMS_URL ?? ''
  }
  public static get publicExternalAppUrl(): string {
    return process.env.REACT_APP_EXTERNAL_APP_URL ?? ''
  }
  public static get publicAppDevMode(): boolean {
    return process.env.REACT_APP_DEV_MODE === 'true'
  }
}

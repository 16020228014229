import { Avatar, Button, LoadingOverlay, SelectField, TextArea, TextField, Toast } from '../../components'
import { useEffect, useRef, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { FiSend } from 'react-icons/fi'
// import Cookies from 'js-cookie'
// import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
// import { getClientRecaptchaToken } from '../../lib/api/recaptcha-auth'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import logoAvatar from '../../images/avatars/SimpsonTiny.jpg'
import { clientFetch } from '../../lib/api/client-fetch'
import { renderAsync } from '@react-email/render'
import ContactNotificationEmail from '../../components/emailTemplates/ContactNotificationEmail'
import { TContactFormValues, TEmailMeta, TContactReferralSources } from 'app-platform-shared-types'

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = () => {
  const formRef = useRef<HTMLFormElement>(null)

  const [showModalBusy, setShowModalBusy] = useState(false)
  const [showSendSuccess, setShowSendSuccess] = useState(false)
  const [showSendFailure, setShowSendFailure] = useState(false)

  const navigate = useNavigate()
  const [t] = useTranslation()

  useEffect(() => {
    setShowModalBusy(false)
  }, [])

  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    clearErrors,
    formState: { errors },
  } = useForm<TContactFormValues>()

  const onSubmit: SubmitHandler<TContactFormValues> = async (data: TContactFormValues) => {
    // console.log('onSubmit: SubmitHandler<TContactFormValues>', data)
    setShowModalBusy(true)

    const jsx = ContactNotificationEmail({
      contactForm: data,
    }) as JSX.Element

    const renderedContent: TEmailMeta = {
      emailHtml: await renderAsync(jsx, {
        pretty: true,
      }),
      emailText: await renderAsync(jsx, {
        plainText: true,
      }),
      toEmail: '',
      fromEmail: '',
      subject: '',
    }

    const result = await clientFetch<TEmailMeta>(`/emails/contact`, {
      method: 'post',
      data: JSON.stringify(renderedContent),
    })

    if (!result) {
      console.error(`Error sending contact email`, result)
      setShowSendFailure(true)
    } else {
      formReset()
      setShowSendSuccess(true)
      setTimeout(() => {
        navigate('/')
      }, 2000)
    }

    // const recaptchaToken = await getClientRecaptchaToken()
    // const expiry = DateTime.utc().plus({ seconds: 30 }).toJSDate()
    // Cookies.set('grecaptcha', recaptchaToken, { expires: expiry })

    // const email = await sendContactEmail(data)
    // console.log('onSubmit: Result Email', email)
    setShowModalBusy(false)
    // if (!email || email.status !== 'SUCCESS') {
    //   setShowSendFailure(true)
    // } else {
    //   formReset()
    //   setShowSendSuccess(true)
    // }
  }

  const formReset = () => {
    formRef.current && formRef.current.reset()
    clearErrors()
  }

  register('firstName', { required: true })
  register('lastName', { required: true })
  register('email', { required: true })
  register('contactNumber', { required: false })
  register('companyName', { required: false })
  register('message', { required: true })
  register('referral', { required: false })

  const getForm = () => (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      // onSubmit={e => handleSubmit(e)}
      className="mt-10 grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2"
    >
      <TextField
        label={`${t('contact.firstName')} *`}
        id="first_name"
        name="first_name"
        type="text"
        autoComplete="given-name"
        isRequired
        onChange={(val: string) => setFormValue('firstName', val?.trim())}
        isInvalid={errors.firstName ? true : false}
        errorMessage={errors.firstName ? t('contact.firstNameReq') : undefined}
      />
      <TextField
        label={`${t('contact.lastName')} *`}
        id="last_name"
        name="last_name"
        type="text"
        autoComplete="family-name"
        isRequired
        onChange={(val: string) => setFormValue('lastName', val?.trim())}
        isInvalid={errors.lastName ? true : false}
        errorMessage={errors.lastName ? t('contact.lastNameReq') : undefined}
      />
      <TextField
        className="col-span-full"
        label={`${t('contact.email')} *`}
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        isRequired
        onChange={(val: string) => setFormValue('email', val?.trim())}
        isInvalid={errors.email ? true : false}
        errorMessage={errors.email ? t('contact.emailReq') : undefined}
      />
      <TextField
        className="col-span-full"
        label={t('contact.company')}
        id="company"
        name="company"
        type="text"
        autoComplete="organization"
        onChange={(val: string) => setFormValue('companyName', val?.trim())}
      />
      <TextField
        className="col-span-full"
        label={t('contact.phoneNum')}
        id="phone"
        name="phone"
        type="text"
        autoComplete="tel"
        onChange={(val: string) => setFormValue('contactNumber', val?.trim())}
      />
      <SelectField
        className="col-span-full"
        label={t('contact.howDidYouHear')}
        id="referral_source"
        name="referral_source"
        onChange={(val: string) => setFormValue('referral', val as TContactReferralSources)}
      >
        <>
          <option value="-1" className="text-gray-400">
            {t('contact.howDidYouHearSelectAnOption')}
          </option>
          <option value="SEARCH">{t('contact.howDidYouHearSearch')}</option>
          <option value="SOCIAL">{t('contact.howDidYouHearSocial')}</option>
          <option value="WORD_OF_MOUTH">{t('contact.howDidYouHearWordOfMouth')}</option>
          <option value="ADS">{t('contact.howDidYouHearAds')}</option>
        </>
      </SelectField>
      <TextArea
        rows={7}
        label={`${t('contact.message')} *`}
        name="message"
        id="message"
        className="col-span-full"
        defaultValue={''}
        isRequired
        placeholder={t('contact.messagePlaceholder')}
        onChange={(val: string) => setFormValue('message', val?.trim())}
        isInvalid={errors.message ? true : false}
        errorMessage={errors.message ? t('contact.messageReq') : undefined}
      />
      <div className="col-span-full">
        <Button type="submit" theme="primary" className="w-full justify-center p-2">
          <span className="flex justify-center">
            <span className="mr-2">{t('contact.submitButton')}</span>
            <FiSend className="text-xl" />
          </span>
        </Button>
      </div>
    </form>
  )

  return (
    <>
      <div className="font-montserrat">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <Button
              className="rounded-full text-white text-xl justify-center items-center p-2"
              col={true}
              theme="primary"
              onPress={() => navigate('/')}
            >
              <FaArrowLeft />
            </Button>

            <Avatar className="text-white h-[3.5rem] w-[3.5rem] rounded-full" imageUrl={logoAvatar} />
          </div>
          <div className="mt-20">
            <h2 className="text-lg font-semibold text-gray-900">{t('contact.title')}</h2>
          </div>
        </div>
        <LoadingOverlay show={showModalBusy} text={t('contact.modalTextSending')} />
        {showSendSuccess && (
          <Toast
            headerText={t('contact.toastSuccessHeader')}
            bodyText={t('contact.toastSuccessBody')}
            isError={false}
            timeMs={3000}
            callback={() => setShowSendSuccess(false)}
          />
        )}
        {showSendFailure && (
          <Toast
            headerText={t('contact.toastErrorHeader')}
            bodyText={t('contact.toastErrorBody')}
            isError={true}
            timeMs={4000}
            callback={() => setShowSendFailure(false)}
          />
        )}

        {getForm()}
      </div>
    </>
  )
}

export default ContactForm

interface AdminLayoutProps {
  children: React.ReactNode
}

const AdminLayout: React.FC<AdminLayoutProps> = (props: AdminLayoutProps) => {
  return (
    <>
      <div className="grid grid-cols-[1fr] md:grid-cols-[2fr_6fr_2fr] gap-1">
        <div className="md:col-start-2 md:col-end-3 flex flex-wrap gap-2 w-full justify-center ">{props.children}</div>
      </div>
    </>
  )
}

export default AdminLayout

import clsx from 'clsx'

const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'

interface LabelProps {
  id: string
  children: React.ReactNode
}

function Label({ id, children }: LabelProps) {
  return (
    <label htmlFor={id} className="mb-3 block text-sm font-medium text-gray-700">
      {children}
    </label>
  )
}

export type TSelectFieldOptions = {
  key: string
  value: string
  selected: boolean
}

interface SelectFieldProps {
  id: string
  label: string
  className?: string
  name: string
  children?: React.ReactNode
  options?: Array<TSelectFieldOptions>
  onChange?: (val: string) => void
}

const SelectField: React.FC<SelectFieldProps> = ({
  id,
  label,
  className = '',
  children,
  options,
  onChange,
  ...props
}: SelectFieldProps) => {
  const selectedVal = options && options.find(o => o.selected)
  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select
        id={id}
        {...props}
        className={clsx(formClasses, 'pr-8')}
        value={selectedVal?.key}
        onChange={e => onChange && onChange(e.target.value)}
      >
        {options &&
          options.map(o => (
            <option key={o.key} value={o.key} className="text-gray-600 p-7 m-5 h-5">
              {o.value}
            </option>
          ))}
        {!options && children}
      </select>
    </div>
  )
}

export default SelectField

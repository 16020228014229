import { useTranslation } from 'react-i18next'
import { Avatar, Button, LoadingOverlay } from '../components'
import logoAvatar from '../images/avatars/SimpsonTiny.jpg'
import { useNavigate } from 'react-router-dom'
import { AiOutlineMessage } from 'react-icons/ai'
import { FaSignOutAlt } from 'react-icons/fa'
import { PiSwimmingPoolBold } from 'react-icons/pi'
import { BsGoogle } from 'react-icons/bs'
import { ClientConfig } from '../config/client.config'
import { clearAuth, getAuth } from '../lib/helpers/auth.helper'
import { useState } from 'react'

const Home: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)

  const authUser = getAuth()

  return (
    <>
      <main>
        <LoadingOverlay show={loading} text={'Loading'} />
        <div className="bg-white">
          <header className="absolute inset-x-0 top-0"></header>

          <div className="flex justify-center">
            <div className="mx-auto max-w-2xl py-32">
              <div className="text-center">
                <div className="flex justify-center w-full">
                  <Avatar className="h-28 w-28 rounded-full m-2 bg-white" imageUrl={logoAvatar} />
                </div>
                {/* <h1 className="text-4xl sm:text-6xl font-bold tracking-tight text-midtone ">
                  {t.home.server.subtitle}
                </h1> */}
                <h3 className="text-2xl sm:text-4xl pt-5 tracking-tight text-accent ">David Isserlin</h3>
                {authUser?.id ? (
                  <>
                    <div className="flex justify-center align-middle my-6">
                      <Button
                        onPress={() => navigate('pools')}
                        className=" min-w-[145px]  text-2xl flex justify-between align-middle p-2"
                        theme="primary"
                      >
                        {t('pool.titlePools')}
                        <PiSwimmingPoolBold />
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="mt-12 text-3xl leading-8 text-midtone">{t('home.header')}</p>
                  </>
                )}

                <div className="mt-10 flex items-center justify-center gap-x-6">
                  {authUser?.id ? (
                    <>
                      <Button
                        className="justify-between min-w-[145px] pl-3 pr-0 py-2 text-md"
                        theme="neutral"
                        onPress={() => {
                          clearAuth()
                          window.location.href = `${ClientConfig.publicAuthBaseUrl}/logout`
                        }}
                      >
                        <span>{t('nav.signOut')}</span>
                        <FaSignOutAlt aria-hidden="true" className="text-lg mr-2" />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="justify-between min-w-[145px] pl-3 pr-0 py-2 text-md"
                        theme="primary"
                        onPress={() => {
                          setLoading(true)
                          window.location.href = `${ClientConfig.publicAuthBaseUrl}/google`
                        }}
                      >
                        <span>{t('nav.signIn')}</span>
                        <BsGoogle className="text-lg mr-2" />
                      </Button>
                    </>
                  )}

                  <Button
                    className="justify-between min-w-[145px] pl-3 pr-0 py-2 text-md"
                    theme="secondary"
                    onPress={() => navigate('/Contact')}
                  >
                    <span>{t('contact.submitButton')}</span>
                    <AiOutlineMessage className="mt-1 mx-2" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Home

import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { FaHome, FaSignInAlt, FaSignOutAlt, FaUserShield } from 'react-icons/fa'
import { Avatar, Button } from '.'
import random from 'random'
import { IconType } from 'react-icons/lib'
import { PiSwimmingPoolBold } from 'react-icons/pi'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ClientConfig } from '../config/client.config'
import { clearAuth, getAuth } from '../lib/helpers/auth.helper'
import { Locale, supportedLanguages } from '../config/i18n.config'

type TNavItem = {
  name: string
  href: string
  icon: IconType
  authOnly: boolean
  adminOnly?: boolean
}

interface NavProps {
  setLocale: Dispatch<SetStateAction<Locale>>
}

const Nav: React.FC<NavProps> = (props: NavProps) => {
  // const { data: session, status } = useSession()
  // console.log('session', session)
  // console.log('status', status)
  const randomNum = random.int(0, 100)
  const { t, i18n } = useTranslation()
  const authUser = getAuth()

  const [navigation, setNavigation] = useState<Array<TNavItem>>([])

  useEffect(() => {
    const updtNav = [{ name: 'Home', href: '/', icon: FaHome, authOnly: false } as TNavItem]
    if (authUser?.id) {
      updtNav.push({ name: 'Pools', href: '/pools', icon: PiSwimmingPoolBold, authOnly: true } as TNavItem)
    }
    if (authUser?.isAdmin) {
      updtNav.push({ name: 'Admin', href: '/admin', icon: FaUserShield, authOnly: true, adminOnly: true } as TNavItem)
    }
    setNavigation(updtNav)
  }, [authUser?.id, authUser?.isAdmin])

  const localizedName = (navItem: TNavItem): string => {
    switch (navItem.name) {
      case 'Home':
        return t('common.home')
      case 'Pools':
        return t('pool.titlePools')
      case 'PoolsAdmin':
        return t('pool.admin')
      case 'Admin':
        return t('admin.adminNav')
      default:
        return '-'
    }
  }
  const changeLanguage = (lng: Locale) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('selectedLanguage', lng)
  }

  return (
    <Disclosure as="nav" className="bg-white border-b border-shadow border-opacity-50">
      {() => (
        <>
          <div className="relative flex justify-between align-middle">
            <div className="flex items-center">
              <Link to="/">
                <span className="text-shadow opacity-50 ml-4 font-bold text-xl">DAVID ISSERLIN</span>
              </Link>
            </div>
            <div>
              {ClientConfig.publicAppDevMode && <div className="bg-accent text-white text-xl h-full p-1">DEV MODE</div>}
            </div>

            {/* Profile dropdown */}
            <Menu as="div" className="relative mt-1 mr-3">
              <div>
                <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-1 focus:ring-accent focus:ring-offset-1">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">{t('nav.openUserMenu')}</span>
                  <Avatar
                    size="xs"
                    className="m-1"
                    imageUrl={authUser?.imgUrl ?? `https://robohash.org/Boogie${randomNum}.png`}
                    name={authUser?.displayName ?? 'User Avatar'}
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <>
                    {navigation.map(n => (
                      <Menu.Item key={n.name}>
                        {({ active }) => (
                          <Link
                            to={n.href}
                            className={clsx(
                              'hover:cursor-pointer',
                              active ? 'bg-highlight text-white' : 'text-gray-700 ',
                              'flex justify-between px-4 py-2 text-sm text-gray-700',
                            )}
                          >
                            <span>{localizedName(n)}</span>
                            <n.icon
                              className={clsx(
                                active ? 'text-white' : 'text-gray-700 group-hover:text-gray-100',
                                'h-6 w-6 shrink-0',
                              )}
                              aria-hidden="true"
                            />
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </>
                  {authUser?.id ? (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            clearAuth()
                            window.location.href = `${ClientConfig.publicAuthBaseUrl}/logout`
                          }}
                          className={clsx(
                            'hover:cursor-pointer',
                            active ? 'bg-highlight text-white' : 'text-gray-700 ',
                            'flex justify-between px-4 py-2 text-sm text-gray-700',
                          )}
                        >
                          <span>{t('nav.signOut')}</span>
                          <FaSignOutAlt
                            className={clsx(
                              active ? 'text-white' : 'text-gray-700 group-hover:text-gray-100',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </Menu.Item>
                  ) : (
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href={`${ClientConfig.publicAuthBaseUrl}/google`}
                          className={clsx(
                            active ? 'bg-highlight text-white' : '',
                            'flex justify-between px-4 py-2 text-sm text-gray-700',
                          )}
                        >
                          <span>{t('nav.signIn')}</span>
                          <FaSignInAlt
                            className={clsx(
                              active ? 'text-white' : 'text-gray-700 group-hover:text-gray-100',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                        </a>
                      )}
                    </Menu.Item>
                  )}
                  <div className="flex flex-row justify-between mt-3 p-1 border-t">
                    {supportedLanguages.map(sl => (
                      <Menu.Item key={sl.code}>
                        <>
                          <Button
                            className="w-[35px] text-center border-none"
                            theme="neutral"
                            onPress={() => changeLanguage(sl.code)}
                          >
                            <span className="w-full h-full text-gray-500">{sl.label}</span>
                          </Button>
                        </>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          {/* {open && (
            <Disclosure.Panel>
              <div className="relative">
                <div className="absolute border border-gray-200 rounded ml-1 mt-1 shadow-md">
                  <NavSidebar />
                </div>
              </div>
            </Disclosure.Panel>
          )} */}
        </>
      )}
    </Disclosure>
  )
}

export default Nav

import { TApiResponse, TPropertyDto } from 'app-platform-shared-types'
import { clientFetch } from './client-fetch'

export const getProperties = async (
  page: number = 1,
  pageSize: number = 25,
  // filterOptions?: any,
  // sortOptions?: any,
): Promise<TApiResponse<TPropertyDto>> => {
  const result = await clientFetch<TPropertyDto>(`/properties?page=${page}&pageSize=${pageSize}`, {
    method: 'get',
  })

  const response = {
    data: result?.data,
    meta: result?.meta,
  } as TApiResponse<TPropertyDto>

  // console.log('apiResponse', response)

  return response
}

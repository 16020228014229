import { TUserDto } from 'app-platform-shared-types'
import { ClientConfig } from '../../config/client.config'

export const setAuth = (userToken: TUserDto): void => {
  localStorage.setItem('authUser', JSON.stringify(userToken))
}

// TODO: Replace cookie session and validated signature
export const getAuth = (): TUserDto | undefined => {
  const authUserString = localStorage.getItem('authUser')
  if (!authUserString || authUserString.length === 0) {
    return
  } else {
    return JSON.parse(authUserString) as TUserDto
  }
}

export const isAuthenticated = (): boolean => {
  return getAuth() !== undefined
}

export const clearAuth = (): void => {
  localStorage.setItem('authUser', '')
}

export const getRecaptchaRespToken = async (window: Window & typeof globalThis, action: string): Promise<string> => {
  const myWindow = window as any
  return await myWindow.grecaptcha.execute(ClientConfig.publicRecaptchaSiteKey, {
    action,
  })
}

export const resetRecaptcha = (window: Window & typeof globalThis) => {
  const myWindow = window as any
  return myWindow.grecaptcha?.reset()
}

export const sanitizeInputHtml = (html: string): string => {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

export const formatCurrency = (amt: number): string => {
  const currencySign = '$'
  let result = ''
  let isNegative = false
  if (!isNaN(amt)) {
    isNegative = amt < 0
    amt = isNegative ? amt * -1 : amt
    result = amt.toFixed(2)
  }

  return `${isNegative ? '-' : ''}${currencySign}${result}`
}

import { useEffect, useState } from 'react'
import { Breadcrumb, Breadcrumbs, type Key } from 'react-aria-components'
import { FaChevronRight } from 'react-icons/fa'
import { IconType } from 'react-icons/lib'
import { Link } from 'react-router-dom'

export type TBreadCrumb = {
  id: string
  label: string
  href?: string
  icon?: IconType
}

interface BreadCrumbsProps {
  allBreadCrumbs: Array<TBreadCrumb>
  onNavigate?: (id: Key) => void
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ allBreadCrumbs, onNavigate }: BreadCrumbsProps) => {
  const [breadcrumbs, setBreadCrumbs] = useState<Array<TBreadCrumb>>([])

  useEffect(() => {
    setBreadCrumbs([...allBreadCrumbs])
  }, [allBreadCrumbs])

  const isNotLast = (id: Key): boolean => breadcrumbs.findIndex(bc => bc.id === id) !== breadcrumbs.length - 1

  return (
    <Breadcrumbs className="w-full flex flex-row justify-center bg-highlight" items={breadcrumbs} onAction={onNavigate}>
      {item => (
        <Breadcrumb className="text-lg text-shadow flex flex-row flex-nowrap gap-2 items-center cursor-pointer">
          <Link to={item.href ?? ''}>
            {item.icon && <item.icon></item.icon>}
            {item.label.length > 0 && <div>{item.label}</div>}
          </Link>
          {isNotLast(item.id) && (
            <span className="text-md mr-3">
              <FaChevronRight className="h-3 w-3 flex-shrink-0 text-shadow" />
            </span>
          )}
        </Breadcrumb>
      )}
    </Breadcrumbs>
  )
}

export default BreadCrumbs

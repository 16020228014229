import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import { registerLocale, setDefaultLocale } from 'react-datepicker'
// import enCA from 'date-fns/locale/en-CA'
// registerLocale('en-CA', enCA)

interface MyDatePickerProps {
  selectedDate?: Date | undefined
  onChangeFn: (date: Date) => void
}

const MyDatePicker: React.FC<MyDatePickerProps> = ({ selectedDate, onChangeFn }: MyDatePickerProps) => {
  return (
    <>
      <DatePicker selected={selectedDate} onChange={onChangeFn} dateFormat={'yyyy-MM-dd'} />
    </>
  )
}

export default MyDatePicker

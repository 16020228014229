import React, { useState } from 'react'
import { LoadingOverlay, Toast, Button } from '../components'
import { useTranslation } from 'react-i18next'
import { ClientConfig } from '../config/client.config'
import { BsGoogle } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const callbackUrl = queryParams.get('callbackUrl') ?? ''

  const [loading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showInvitationRequested, setShowInvitationRequested] = useState(false)
  const [showInvitationAccepted, setShowInvitationAccepted] = useState(false)
  const [showInvitationRefused, setShowInvitationRefused] = useState(false)
  const [toastError] = useState<string | undefined>('Error')

  // console.log("location", location)
  // useEffect(() => {
  //   if (stateErr) {
  //     setToastError(stateErr)
  //     setShowError(true)
  //     navigate(location.pathname, { replace: true })
  //   }
  // }, [])

  // const requestInvitationByEmail = async () => {
  //   if (!invitationEmail || invitationEmail.length === 0) {
  //     setInvitationEmailValid(false)
  //   } else {
  //     setLoading(true)
  //     const result = await requestInvitation(invitationEmail)

  //     let invitation: Invitation | undefined = undefined
  //     if (result?.data && result.data.length > 0) {
  //       invitation = result.data[0]
  //     }

  //     if (!invitation) {
  //       throw new Error('Login::requestInvitationByEmail unexcpected result')
  //     }
  //     console.log('Login::requestInvitationByEmail', invitation)

  //     switch (invitation.status) {
  //       case INVITATION_STATUS.requested:
  //         setShowInvitationRequested(true)
  //         break

  //       case INVITATION_STATUS.accepted:
  //         setShowInvitationAccepted(true)

  //         break

  //       case INVITATION_STATUS.rejected:
  //       case INVITATION_STATUS.expired:
  //         setShowInvitationRefused(true)

  //         break
  //       default:
  //         throw new Error('Unexpected invitation status')
  //     }

  //     setLoading(false)
  //   }
  // }

  return (
    <>
      <LoadingOverlay show={loading} text={t('common.loading')} />
      {showError && (
        <Toast
          headerText={t('login.errorText')}
          bodyText={toastError ?? ''}
          isError={true}
          timeMs={3000}
          callback={() => setShowError(false)}
        />
      )}
      {showInvitationRequested && (
        <Toast
          headerText={t('login.invitationRequestedHeader')}
          bodyText={t('login.invitationRequestedBody') ?? ''}
          isError={false}
          timeMs={5000}
          callback={() => setShowInvitationRequested(false)}
        />
      )}
      {showInvitationAccepted && (
        <Toast
          headerText={t('login.invitationAcceptedHeader')}
          bodyText={t('login.invitationAcceptedBody') ?? ''}
          isError={false}
          timeMs={3000}
          callback={() => setShowInvitationAccepted(false)}
        />
      )}
      {showInvitationRefused && (
        <Toast
          headerText={t('login.invitationRefusedHeader')}
          bodyText={t('login.invitationRefusedBody') ?? ''}
          isError={true}
          timeMs={3000}
          callback={() => setShowInvitationRefused(false)}
        />
      )}

      <div className="relative flex justify-center w-screen h-screen">
        <div className="flex flex-col items-center fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="text-2xl mb-3 font-bold text-shadow">{t('home.header')}</div>
          <Button
            className="m-2 p-2 w-full flex flex-row justify-center items-center"
            theme="primary"
            onPress={() =>
              (window.location.href = `${ClientConfig.publicAuthBaseUrl}/google?callbackUrl=${callbackUrl}`)
            }
          >
            <BsGoogle className="mt-1 mx-2 text-2xl" />
            <span>{t('nav.signIn')}</span>
          </Button>
        </div>
      </div>
    </>
  )
}

export default Login

import { FC } from 'react'
import { Properties } from '../components'

interface ListingsProps {
  className?: string
}

const Listings: FC<ListingsProps> = ({ className }) => {
  return (
    <>
      <Properties></Properties>
    </>
  )
}

export default Listings

'use client'
import { PressEvent, useButton, useHover, usePress } from 'react-aria'
import { useRef } from 'react'
import { VariantProps, tv } from 'tailwind-variants'

const buttonTV = tv({
  base: 'flex-nowrap  rounded cursor-pointer',
  variants: {
    row: {
      true: 'flex flex-row items-center',
    },
    col: {
      true: 'flex flex-col',
    },
    disabled: {
      true: 'cursor-not-allowed  opacity-50',
    },
    theme: {
      primary: 'bg-accent text-white hover:bg-highlight hover:shadow-xl',
      secondary: 'bg-midtone text-white hover:bg-highlight hover:shadow-xl',
      neutral: 'bg-white text-black hover:text-white hover:bg-highlight hover:shadow-xl border border-highlight',
      danger: 'bg-red-600 text-white  hover:bg-red-400 hover:shadow-xl',
    },
  },
  defaultVariants: {
    row: true,
    theme: 'neutral',
  },
})

export type TButtonVariants = VariantProps<typeof buttonTV>

interface ButtonProps extends TButtonVariants {
  children: React.ReactNode | undefined
  className: string | undefined
  type?: 'button' | 'submit' | 'reset'
  title?: string | undefined
  onPressStart?: (e: PressEvent) => void
  onPressEnd?: (e: PressEvent) => void
  onPress?: (e: PressEvent) => void
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { children, disabled, type } = props
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { buttonProps } = useButton(props, buttonRef)

  const { hoverProps } = useHover({
    onHoverStart: () => {},
    onHoverEnd: () => {},
  })

  const { pressProps } = usePress({
    onPressStart: (e: any) => props.onPressStart && props.onPressStart(e),
    onPressEnd: (e: any) => props.onPressEnd && props.onPressEnd(e),
    onPress: (e: any) => props.onPress && props.onPress(e),
  })

  return (
    <>
      <button
        title={props.title}
        type={type ?? 'button'}
        className={buttonTV(props)}
        disabled={disabled}
        {...buttonProps}
        {...hoverProps}
        {...pressProps}
        ref={buttonRef}
      >
        {children}
      </button>
    </>
  )
}

export default Button

import { Activity, Contact, Home, Listings, Login, Pools } from './pages'
import { Forbidden, NotAllowed, NotFound } from './components'
import Pool from './pages/pools/pool/Pool'
import PoolAdmin from './pages/admin/pools/PoolAdmin'
import UserAdmin from './pages/admin/users/UserAdmin'
import Admin from './pages/admin/Admin'

interface RouteProps {
  key: string
  title: string
  path: string
  enabled: boolean
  adminOnly: boolean
  component: React.FC<React.PropsWithChildren<any>>
  orderedLayouts?: Array<React.FC<React.PropsWithChildren<any>>>
}

const routes: Array<RouteProps> = [
  {
    key: 'contact-route',
    title: 'Contact',
    path: '/contact',
    enabled: true,
    adminOnly: false,
    component: Contact,
  },
  {
    key: 'home-route',
    title: 'Home',
    path: '/',
    enabled: true,
    adminOnly: false,
    component: Home,
  },
  {
    key: 'listings-route',
    title: 'Listings',
    path: '/listings',
    enabled: true,
    adminOnly: false,
    component: Listings,
  },
  {
    key: 'login-route',
    title: 'Login',
    path: '/login',
    enabled: true,
    adminOnly: false,
    component: Login,
  },
  {
    key: 'pools-route',
    title: 'Pools',
    path: '/Pools',
    enabled: true,
    adminOnly: false,
    component: Pools,
  },
  {
    key: 'pool-route',
    title: 'Pool',
    path: '/Pools/:poolId/:action?',
    enabled: true,
    adminOnly: false,
    component: Pool,
  },
  {
    key: 'pool-activity-route',
    title: 'Activity',
    path: '/Pools/:poolId/Activity/:activityId/:action?',
    enabled: true,
    adminOnly: false,
    component: Activity,
  },
  {
    key: 'admin-route',
    title: 'Admin',
    path: '/admin',
    enabled: true,
    adminOnly: true,
    component: Admin,
  },
  {
    key: 'pool-admin-route',
    title: 'Pool Admin',
    path: '/pools/admin',
    enabled: true,
    adminOnly: true,
    component: PoolAdmin,
  },
  {
    key: 'user-admin-route',
    title: 'User Admin',
    path: '/users/admin',
    enabled: true,
    adminOnly: true,
    component: UserAdmin,
  },
  {
    key: 'FourOhOne-route',
    title: '401',
    path: '/401',
    enabled: true,
    adminOnly: false,
    component: NotAllowed,
  },
  {
    key: 'FourOhThree-route',
    title: '403',
    path: '/403',
    enabled: true,
    adminOnly: false,
    component: Forbidden,
  },
  {
    key: 'FourOhFour-route',
    title: '404',
    path: '*',
    enabled: true,
    adminOnly: false,
    component: NotFound,
  },
]

export default routes

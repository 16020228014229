import { useEffect, useState } from 'react'
import { getAdminPoolEmails, getAdminPools, postAdminResendEmail } from '../../../lib/api/pool-admin-fetch'
import { TApiResponse, TApiResponseMeta, TEmailDto, TPoolDto } from 'app-platform-shared-types'
import { LoadingOverlay, Paginate, SelectField } from '../../../components'
import { TSelectFieldOptions } from '../../../components/core/SelectField'
import { useTranslation } from 'react-i18next'
import PoolCard from '../../pools/_components/PoolCard'
import AdminLayout from '../../../layouts/AdminLayout'
import { DateTime } from 'luxon'
import { FaCheck, FaCross, FaRepeat } from 'react-icons/fa6'

interface PoolAdminProps {}

const PoolAdmin: React.FC<PoolAdminProps> = () => {
  const [t] = useTranslation()
  const [poolsLoading, setPoolsLoading] = useState<boolean>(true)
  const [emailsLoading, setEmailsLoading] = useState<boolean>(false)
  const [emailResending, setEmailResending] = useState<boolean>(false)
  const [pools, setPools] = useState<Array<TPoolDto>>([])
  const [pool, setPool] = useState<TPoolDto | undefined>(undefined)
  const [emails, setEmails] = useState<Array<TEmailDto>>([])
  const [emailMeta, setEmailMeta] = useState<TApiResponseMeta | undefined>(undefined)

  useEffect(() => {
    getAdminPools().then((p: Array<TPoolDto>) => {
      setPools(p)
      if (p?.length) {
        setPool(p[0])
        setPoolsLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (pool) {
      setEmailsLoading(true)
      getAdminPoolEmails(pool.id, 0, 10).then((e: TApiResponse<TEmailDto> | undefined) => {
        setEmails(e?.data ?? [])
        setEmailMeta(e?.meta)
        setEmailsLoading(false)
      })
    }
  }, [pool])

  const toggleSelectedPool = (poolId: string) => {
    setPool(pools.find(p => p.id === poolId))
  }

  return (
    <AdminLayout>
      <LoadingOverlay
        show={poolsLoading || emailsLoading || emailResending}
        text={emailResending ? t('common.sending') : t('common.loading')}
      />
      <div className="flex flex-col items-center">
        <SelectField
          className="col-span-full"
          label={t('pool.titlePools')}
          id="referral_source"
          name="referral_source"
          onChange={(val: string) => toggleSelectedPool(val)}
          options={pools.map(p => {
            return {
              key: p.id,
              value: p.name,
              selected: pool?.id === p.id,
            } as TSelectFieldOptions
          })}
        ></SelectField>
        {pool && (
          <div className="my-2">
            <PoolCard key={pool.id} pool={pool} size="md" />
          </div>
        )}
        {pool && emails.length > 0 && (
          <div className="flex flex-col items-center mt-4">
            <span>{t('pool.adminPage.emails')}</span>
            {/* <TextField placeholder="search" label="Search Emails" /> */}
            <div className="w-full flex justify-center my-1">
              <Paginate
                pageNum={emailMeta?.pagination.page ?? 0}
                pageCount={emailMeta?.pagination.pageCount ?? 0}
                onPageChange={(page: number): void => {
                  setEmailsLoading(true)
                  getAdminPoolEmails(pool.id, page * 10, 10).then((e: TApiResponse<TEmailDto> | undefined) => {
                    setEmails(e?.data ?? [])
                    setEmailMeta(e?.meta)
                    setEmailsLoading(false)
                  })
                }}
              />
            </div>
            <div className="w-full grid grid-cols-[6fr_4fr_2fr_2fr] gap-1 border-b mt-2 font-bold">
              <div>{t('pool.adminPage.emailTo')}</div>
              <div>{t('pool.adminPage.emailSubject')}</div>
              <div>{t('pool.adminPage.emailDate')}</div>
              <div>{t('pool.adminPage.emailStatus')}</div>
            </div>
            {emails.map(e => (
              <div className="w-full grid grid-cols-[6fr_4fr_2fr_2fr] gap-1 border-b mt-2">
                <div>{e.to}</div>
                <div>{e.subject}</div>
                <div>{DateTime.fromISO(e.updatedAtIso).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}</div>
                <div className="flex items-center text-xl">
                  {e.status === 'SUCCESS' ? (
                    <FaCheck className="text-green-600" />
                  ) : (
                    <FaCross className="text-red-600" />
                  )}
                  <FaRepeat
                    className="mx-2 cursor-pointer"
                    title={t('pool.adminPage.resendEmail')}
                    onClick={() => {
                      if (window.confirm(t('pool.adminPage.resendConfirm', { to: e.to }))) {
                        setEmailResending(true)
                        postAdminResendEmail(pool.id, e.id).then(e => {
                          e?.data.length && setEmails([e.data[0], ...emails])
                          setEmailResending(false)
                        })
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

export default PoolAdmin

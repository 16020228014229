import clsx from 'clsx'
import { useRef } from 'react'
import type { AriaTextFieldProps } from 'react-aria'
import { useTextField } from 'react-aria'

interface TextFieldProps extends AriaTextFieldProps {
  className?: string
  label?: string
  placeholder?: string
  description?: string
  inputClassName?: string
  errorMessage?: string
  disabled?: boolean
}

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const { label, placeholder, disabled } = props
  const ref = useRef(null)
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref)

  return (
    <div className={clsx('flex flex-col', props.className)}>
      {label && (
        <label {...labelProps} className="text-sm font-bold">
          {label}
        </label>
      )}
      <input
        placeholder={placeholder}
        {...inputProps}
        disabled={props.disabled}
        className={clsx(
          'rounded border-highlight focus:outline-none focus:border-accent focus:ring-2 focus:ring-transparent',
          props.inputClassName,
          { 'cursor-not-allowed': disabled },
        )}
        ref={ref}
      />
      {props.description && (
        <div {...descriptionProps} className="text-base">
          {props.description}
        </div>
      )}
      {props.errorMessage && (
        <div {...errorMessageProps} className="text-red-500 text-xs">
          {props.errorMessage}
        </div>
      )}
    </div>
  )
}

export default TextField

import { TEmailDto } from 'app-platform-shared-types'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

interface ActivityEmailDetailsProps {
  email: TEmailDto | undefined
}

const ActivityEmailDetails: React.FC<ActivityEmailDetailsProps> = ({ email }: ActivityEmailDetailsProps) => {
  const [t] = useTranslation()
  if (!email) {
    return null
  }
  return (
    <>
      <div className="grid grid-cols-[1fr_.5fr_4fr]">
        <div className="font-bold text-lg text-right">{t('pool.activity.activityEmailTo')}</div>
        <div></div>
        <div className="text-lg text-left">{email.to?.toString()}</div>
      </div>
      <div className="grid grid-cols-[1fr_.5fr_4fr]">
        <div className="font-bold text-lg text-right">{t('pool.activity.activityEmailDate')}</div>
        <div></div>
        <div className="text-lg text-left">
          {DateTime.fromISO(email.updatedAtIso).toLocaleString(DateTime.DATETIME_FULL)}
        </div>
      </div>
      <div className="flex flex-col p-4">
        <div className="font-bold text-lg ">{t('pool.activity.activityEmailDetails')}</div>
        <div className="text-lg text-left">{email.text?.toString() ?? '-'}</div>
      </div>
    </>
  )
}

export default ActivityEmailDetails

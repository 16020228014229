import { TEmailDto, TPoolDto } from 'app-platform-shared-types'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaHourglass, FaInfo, FaXmark } from 'react-icons/fa6'

interface ActivityEmailRowProps {
  email: TEmailDto
  pool: TPoolDto
  onInfoClick: (email: TEmailDto) => void
}

const ActivityEmailRow: React.FC<ActivityEmailRowProps> = ({ email, pool, onInfoClick }: ActivityEmailRowProps) => {
  const { t } = useTranslation()
  let sentTo: string | undefined = undefined
  if (email?.poolMemberId) {
    const matchedPM = pool?.poolMembers.find(pm => pm.id === email.poolMemberId)
    sentTo = matchedPM ? matchedPM.displayName : '-'
  } else {
    sentTo = '-'
  }
  const shortDate = DateTime.fromISO(email.updatedAtIso).toLocaleString(DateTime.DATETIME_MED)
  const longDate = DateTime.fromISO(email.updatedAtIso).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
  return (
    <div
      key={email.id}
      className=" overflow- w-full grid grid-cols-[1.5fr_1.5fr_3fr_.5fr] p-2 gap-2 lg:gap-6 text-shadow"
    >
      <div className={'truncate lg:whitespace-normal'} title={sentTo}>
        {sentTo}
      </div>
      <div className={'truncate lg:whitespace-normal'} title={longDate}>
        {shortDate}
      </div>
      <div className={'truncate lg:whitespace-normal'} title={email.subject}>
        {email.subject}
      </div>
      <div className="w-full h-full flex justify-start items-center gap-3 text-xl">
        {email.status === 'SUCCESS' && (
          <>
            <FaCheck className="text-green-700" />
          </>
        )}
        {email.status === 'ERROR' && (
          <>
            <FaXmark className="text-red-700" />
          </>
        )}
        {(email.status === 'SENDING' || email.status === 'CREATED') && (
          <>
            <FaHourglass className="text-gray-700" />
          </>
        )}
        <FaInfo
          className="border border-gray-600 rounded-full p-1 hover:cursor-pointer"
          title={t('pool.activity.activityEmailDetails')}
          onClick={() => onInfoClick(email)}
        />
      </div>
    </div>
  )
}

export default ActivityEmailRow

import React, { Fragment, useEffect, useState } from 'react'
import { PropertyCard } from './PropertyCard'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import clsx from 'clsx'
import LoadingOverlay from './LoadingOverlay'
import { Transition, Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Paginator } from '.'
import { useTranslation } from 'react-i18next'
import { getProperties } from '../lib/api/property-fetch'
import { TApiResponseMeta, TPropertyDto } from 'app-platform-shared-types'

interface PropertiesProps {}

const Properties: React.FC<PropertiesProps> = () => {
  const maxNumberOfPages = 10
  const pageIncrementVal: number = 12

  const [properties, setProperties] = useState<Array<TPropertyDto>>([])
  const [propertiesMeta, setPropertiesMeta] = useState<TApiResponseMeta | undefined>(undefined)
  const [page, setPage] = useState<number>(1)
  const [pageSize] = useState<number>(pageIncrementVal)
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [previewProperty, setPreviewProperty] = useState<TPropertyDto | undefined>(undefined)

  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    getProperties(page, pageSize)
      .then(result => {
        setProperties(result.data)
        setPropertiesMeta(result.meta)
      })
      .catch(err => {
        console.error(err)
        if (err.status !== 401) {
          alert('Error communicating with the server. If this error persists please contact support for assistance.')
        }
      })
      .finally(() => setLoading(false))
  }, [page, pageSize])

  const getPaginationElem = (p: number): JSX.Element => (
    <a key={p} onClick={() => handleNavClick(p)} className={page === p ? navSelectedClass : navDefaultClass}>
      {p}
    </a>
  )

  const handlePropertyClick = (property: TPropertyDto) => {
    setPreviewProperty(property)
    setOpen(true)
  }

  const handleNavClick = (pageNum: number) => {
    setPage(pageNum)
  }

  const prevPage = () => {
    if (page !== 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (propertiesMeta?.pagination && page !== propertiesMeta.pagination.pageCount) {
      setPage(page + 1)
    }
  }

  const baseClass = clsx('inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium')
  const navSelectedClass: string = clsx(baseClass, 'border-indigo-500 text-indigo-600 hover:cursor-pointer')
  const navDefaultClass: string = clsx(
    baseClass,
    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer',
  )

  return (
    <div className="relative">
      <LoadingOverlay show={loading} text={'Loading'} />
      <nav className="flex items-center justify-between border-b border-gray-200 p-4">
        <div className="-mt-px flex w-0 flex-1">
          <a
            onClick={prevPage}
            className={clsx(
              'inline-flex',
              'items-center',
              'border-t-2',
              'border-transparent',
              'pr-1',
              'pt-4',
              'text-sm',
              'font-medium',
              'text-gray-500',
              'hover:border-gray-300',
              'hover:text-gray-700',
              { 'hover:cursor-pointer': page !== 1 },
              { 'hover:cursor-not-allowed': page === 1 },
            )}
          >
            <FaArrowLeft className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            {t('properties.previous')}
          </a>
        </div>
        {propertiesMeta?.pagination && (
          <Paginator
            page={page}
            pageCount={propertiesMeta.pagination.pageCount ?? 0}
            maxNumberOfPages={maxNumberOfPages}
            getPaginationElem={getPaginationElem}
            onChange={(p: number) => setPage(p)}
          />
        )}
        <div className="-mt-px flex w-0 flex-1 justify-end ">
          <a
            onClick={nextPage}
            className={clsx(
              'inline-flex',
              'items-center',
              'border-t-2',
              'border-transparent',
              'pr-1',
              'pt-4',
              'text-sm',
              'font-medium',
              'text-gray-500',
              'hover:border-gray-300',
              'hover:text-gray-700',
              { 'hover:cursor-pointer': propertiesMeta?.pagination && page !== propertiesMeta.pagination.pageCount },
              {
                'hover:cursor-not-allowed': propertiesMeta?.pagination && page === propertiesMeta.pagination.pageCount,
              },
            )}
          >
            {t('properties.next')}
            <FaArrowRight className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </a>
        </div>
      </nav>
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {properties.map(p => (
          <li key={p.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white m-3">
            <PropertyCard key={p.id} style={'compact'} property={p} onPropertyClick={() => handlePropertyClick(p)} />
          </li>
        ))}
      </ul>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative"
          onClose={() => {
            setOpen(false)
            setPreviewProperty(undefined)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-full md:max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {previewProperty && (
                              <div className="flex">
                                <h1 className="text-xl text-accent">
                                  {previewProperty.address1} {previewProperty.address2}
                                </h1>
                              </div>
                            )}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {previewProperty && <PropertyCard style={'preview'} property={previewProperty} />}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export { Properties }
export type { PropertiesProps }

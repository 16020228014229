import { useTranslation } from 'react-i18next'

interface ActivityEmailHeaderRowProps {}

const ActivityEmailHeaderRow: React.FC<ActivityEmailHeaderRowProps> = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full grid grid-cols-[1.5fr_1.5fr_3fr_.5fr] p-2 gap-2 font-semibold lg:gap-6 text-shadow border-b">
      <div>{t('pool.activity.activityEmailTo')}</div>
      <div>{t('pool.activity.activityEmailDate')}</div>
      <div>{t('pool.activity.activityEmailSubject')}</div>
      <div>{t('pool.activity.activityEmailStatus')}</div>
    </div>
  )
}

export default ActivityEmailHeaderRow

import { FaHome } from 'react-icons/fa'
import { IconType } from 'react-icons/lib'
import i18next from 'i18next'
import ActivityNotificationEmail from '../../components/emailTemplates/ActivityNotificationEmail'
import { renderAsync } from '@react-email/render'
import {
  TPoolActivityType,
  TPoolActivityDto,
  TPoolActivityEmailCreateMeta,
  TEmailMeta,
} from 'app-platform-shared-types'

export type TBreadCrumb = {
  id: string
  label: string
  href?: string
  icon?: IconType
}

export const getPoolActivityLocaleText = (activityType: TPoolActivityType): string => {
  let result: string
  switch (activityType) {
    case 'ACCOUNT_CREDIT':
      result = i18next.t('pool.activity.accountCredit')
      break
    case 'ACCOUNT_DEBIT':
      result = i18next.t('pool.activity.accountDebit')
      break
    default:
      throw new Error(`Unknown activity Type: ${activityType}`)
  }

  return result
}

export const getPoolBreadCrumbs = (poolId?: string, poolMemberId?: string, activityId?: string): Array<TBreadCrumb> => {
  const baseUrl = `/`
  const homePoolUrl = `/pools`
  const result: Array<TBreadCrumb> = []

  result.push({
    id: '0',
    href: baseUrl,
    icon: FaHome,
    label: '',
  })

  result.push({
    id: '1',
    href: homePoolUrl,
    label: i18next.t('pool.titlePools'),
  })

  if (poolId) {
    result.push({
      id: poolId,
      href: `${homePoolUrl}/${poolId}`,
      label: i18next.t('pool.titlePool'),
    })
  }

  if (poolMemberId) {
    result.push({
      id: poolMemberId,
      href: `${homePoolUrl}/${poolId}/member/${poolMemberId}`,
      label: i18next.t('pool.titleMember'),
    })
  }

  if (activityId) {
    result.push({
      id: activityId,
      href: `${homePoolUrl}/${poolId}/activity/${activityId}`,
      label: i18next.t('pool.titleActivity'),
    })
  }

  return result
}

export const renderActivityEmails = async (
  poolName: string,
  activity: TPoolActivityDto,
): Promise<Array<TPoolActivityEmailCreateMeta>> => {
  const renderedEmails: Array<TPoolActivityEmailCreateMeta> = []
  const currActivityTransactions = activity.transactions.map(t => t.transaction)
  for (const pm of activity.poolMembers.map(apm => apm.poolMember)) {
    const currPmTx = currActivityTransactions.find(t => t.poolMemberId === pm.id)
    if (!currPmTx) {
      throw new Error('sendActivityEmail: Missing transaction data')
    }

    const jsx = ActivityNotificationEmail({
      poolName,
      receiverPoolMember: pm,
      receiverTransaction: currPmTx,
      activity,
    }) as JSX.Element

    const renderedContent: TEmailMeta = {
      emailHtml: await renderAsync(jsx, {
        pretty: true,
      }),
      emailText: await renderAsync(jsx, {
        plainText: true,
      }),
      toEmail: '',
      fromEmail: '',
      subject: activity.note,
    }

    renderedEmails.push({ poolMemberId: pm.id, transactionId: currPmTx.id, email: renderedContent })
  }
  return renderedEmails
}

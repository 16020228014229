import { TUserDto } from 'app-platform-shared-types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from '../../../components'
import { useEffect, useRef } from 'react'
import { FaFloppyDisk } from 'react-icons/fa6'

interface UserFormProps {
  user: TUserDto
  onCancel: () => void
  onSubmit: (user: TUserDto) => Promise<void>
}

const UserForm: React.FC<UserFormProps> = (props: UserFormProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { t } = useTranslation()
  const {
    control,
    register,
    handleSubmit,
    setValue: setFormValue,
    clearErrors,
    formState: { errors },
  } = useForm<TUserDto>(/*{ defaultValues: { providerUsername: props.user.providerUsername } }*/)

  register('id', { required: false })
  register('displayName', { required: true })
  register('providerUsername', { required: true })

  const handleSubmitUser: SubmitHandler<TUserDto> = (user: TUserDto) => {
    props.onSubmit(user).then(() => {
      formRef.current?.reset()
      clearErrors()
    })
  }

  useEffect(() => {
    if (props.user) {
      setFormValue('id', props.user.id)
      setFormValue('displayName', props.user.displayName)
      setFormValue('providerUsername', props.user.providerUsername)
    }
  }, [props.user, setFormValue])

  return (
    <>
      <div className="w-full text-lg font-bold text-center">
        {props.user?.id ? t('user.updateUser') : t('user.createUser')}
      </div>
      <form ref={formRef} onSubmit={handleSubmit(handleSubmitUser)}>
        <input type="hidden" id="id" name="id" />
        <Controller
          control={control}
          name="displayName"
          render={({ field }) => {
            const { ref, ...rest } = field // removes ref
            return (
              <TextField
                {...rest}
                label={t('user.displayName')}
                type="text"
                autoComplete="name"
                onChange={(val: string) => setFormValue('displayName', val)}
                isInvalid={errors.displayName ? true : false}
                errorMessage={errors.displayName ? t('user.displayNameReq') : undefined}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="providerUsername"
          render={({ field }) => {
            const { ref, ...rest } = field // removes ref
            return (
              <TextField
                {...rest}
                label={`${t('user.providerUsername')} *`}
                type="text"
                autoComplete="email"
                isRequired
                onChange={(val: string) => setFormValue('providerUsername', val?.trim())}
                isInvalid={errors.providerUsername ? true : false}
                errorMessage={errors.providerUsername ? t('user.providerUsernameReq') : undefined}
              />
            )
          }}
        />
        <div className="flex justify-between mt-2 pt-2 border-t">
          <Button
            type="reset"
            theme="neutral"
            className="p-1"
            onPress={() => {
              formRef.current?.reset()
              clearErrors()
              props.onCancel()
            }}
          >
            <span className="flex justify-center">
              <span className="mr-2">{t('user.cancelButton')}</span>
            </span>
          </Button>
          <Button type="submit" theme="primary" className="p-1">
            <span className="flex justify-center">
              <span className="mr-2">{t('user.submitButton')}</span>
              <FaFloppyDisk className="text-xl" />
            </span>
          </Button>
        </div>
      </form>
    </>
  )
}

export default UserForm

import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import ReactPaginate from 'react-paginate'

interface PaginateProps {
  pageNum: number
  pageCount: number
  onPageChange: (page: number) => void
}

const Paginate: React.FC<PaginateProps> = (params: PaginateProps) => {
  const { t } = useTranslation()
  // console.log('Paginate Params:', params)
  return (
    <>
      <ReactPaginate
        className="flex flex-row "
        pageCount={params.pageCount}
        onPageChange={e => params.onPageChange(e.selected)}
        previousLabel={
          <span className="p-1 text-sm h-10 flex justify-center items-center border border-gray-300">
            <FaArrowLeft />
            &nbsp;
            {t('paginator.previous')}
          </span>
        }
        nextLabel={
          <span className="p-1 text-sm h-10 flex justify-center items-center border border-gray-300">
            {t('paginator.next')}&nbsp;
            <FaArrowRight />
          </span>
        }
        pageClassName="w-10 h-10 flex justify-center items-center border border-gray-300" //li items
        disabledClassName="text-gray-900 opacity-50"
        disabledLinkClassName="cursor-not-allowed"
        activeClassName="bg-highlight"
        breakLabel="..."
      />
    </>
  )
}

export default Paginate

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PoolCard from './_components/PoolCard'
import { Link } from 'react-router-dom'
import PoolLayout from '../../layouts/PoolLayout'
import { getPools } from '../../lib/api/pool-fetch'
import { TPoolDto } from 'app-platform-shared-types'
import { LoadingOverlay } from '../../components'

interface PoolsProps {}

const Pools: React.FC<PoolsProps> = () => {
  const { t } = useTranslation()
  const [pageTitle] = useState<string>(t('pool.titlePools'))

  // console.log('Pools poolBreadCrumbLocalize', poolBreadCrumbLocalize)
  const [pools, setPools] = useState<Array<TPoolDto>>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    getPools().then(pools => {
      setPools(pools)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <LoadingOverlay text={t('common.loading')} show={loading} />
      <PoolLayout bannerTitleText={pageTitle}>
        <div className="w-full flex flex-col gap-5">
          <div className="flex flex-row flex-wrap justify-center gap-2">
            {pools &&
              pools.length > 0 &&
              pools?.map(p => (
                <Link key={p.id} to={p.id} className="cursor-pointer  my-1">
                  <PoolCard pool={p} size="md" />
                </Link>
              ))}
          </div>
        </div>
      </PoolLayout>
    </>
  )
}

export default Pools

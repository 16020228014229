import { useTranslation } from 'react-i18next'

interface ActivityTransactionRowHeaderProps {}

const ActivityTransactionRowHeader: React.FC<ActivityTransactionRowHeaderProps> = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full grid grid-cols-[1.5fr_2fr_1fr_1fr_1fr] p-2 gap-2 font-semibold text-shadow">
      <div>{t('pool.activity.activityMemeber')}</div>
      <div>{t('common.date')}</div>
      <div>{t('transaction.initialAmt')}</div>
      <div>{t('transaction.creditDebitAmt')}</div>
      <div>{t('transaction.finalAmt')}</div>
    </div>
  )
}

export default ActivityTransactionRowHeader

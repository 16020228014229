import { useEffect, useState } from 'react'

export function useIsClient() {
  const [isClient, setIsClient] = useState(false)
  useEffect(() => setIsClient(true), [])
  return isClient
}

// export function useAuthSession() {
//   const [authSession, setAuthSession] = useState<AuthSession | undefined>(undefined)
//   const { status, data } = useSession()
//   useEffect(() => {
//     if (status === 'authenticated' && data !== null) {
//       const auth = new AuthSession(data)
//       setAuthSession(auth)
//     }
//   }, [status, data])
//   return { status, authSession }
// }

import React, { createContext, useContext } from 'react'
import { Locale } from '../config/i18n.config'

export type TAppContext = {
  locale: Locale
  // isLoading: boolean
  // setLoading: Dispatch<React.SetStateAction<boolean>>
}

const AppContext = createContext<TAppContext>({
  locale: 'en',
  // isLoading: false,
  // setLoading: () => {},
})

export interface ProvidersProps {
  locale: Locale
  children: React.ReactNode
}

export const AppProvider: React.FC<ProvidersProps> = (props: ProvidersProps) => {
  const appCtx: TAppContext = {
    locale: props.locale,
  }
  return (
    <div>
      <AppContext.Provider value={appCtx}>{props.children}</AppContext.Provider>
    </div>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}

interface NotAllowedProps {}

const NotAllowed: React.FC<NotAllowedProps> = () => {
  return (
    <div>
      <h1>401 - Not Allowed</h1>
      <p>The page you requested requires elevated permisions.</p>
    </div>
  )
}

export default NotAllowed

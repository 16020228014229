import { Html, Text, Container, Hr, Head, Body } from '@react-email/components'
import { TContactFormValues } from 'app-platform-shared-types'

interface ContactNotificationEmailProps {
  contactForm: TContactFormValues
}
const bodyCss = {
  fontSize: '1.125rem',
  lineHeight: '2rem',
}

const ContactNotificationEmail: React.FC<ContactNotificationEmailProps> = ({
  contactForm,
}: ContactNotificationEmailProps) => {
  return (
    <>
      <Html lang="en">
        <Head />
        <Body>
          <Container>
            <Text
              style={{
                fontWeight: 700,
                fontSize: '1.25rem',
                lineHeight: '2rem',
              }}
            >
              An email message was received from the Contact Page of DavidIsserlin.com
            </Text>
            <Hr />
            <Text style={bodyCss}>{`From: ${contactForm.firstName} ${contactForm.lastName}`}</Text>
            <Text style={bodyCss}>{`Email: ${contactForm.email} `}</Text>
            <Text style={bodyCss}>{`Company: ${contactForm.companyName ?? '-'} `}</Text>
            <Text style={bodyCss}>{`Phone: ${contactForm.contactNumber ?? '-'} `}</Text>
            <Text style={bodyCss}>{`Heard about us: ${contactForm.referral ?? '-'} `}</Text>
            <Text style={bodyCss}>{`Message: ${contactForm.message ?? '-'} `}</Text>
          </Container>
        </Body>
      </Html>
    </>
  )
}

export default ContactNotificationEmail

import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { FaAnglesDown, FaAnglesUp } from 'react-icons/fa6'
import { RxCrossCircled, RxPlusCircled } from 'react-icons/rx'
import { Button } from '..'
import { useTranslation } from 'react-i18next'

// Define a base interface that requires an id field
interface WithId {
  id: string
}

export type TItemSelectorMeta = {
  selectedItemsLabel: string
  selectedLabelClassName?: string
  selectedLabelEmptyMessage?: string
  selectedContainerClassName?: string
  unselectedItemsLabel: string
  unselectedLabelClassName?: string
  unselectedLabelEmptyMessage?: string
  unselectedContainerClassName?: string
  atLeastOneItemLabel?: string
  allItemsSelectedLabel?: string
}

interface ItemSelectorProps<T extends WithId> {
  selectedItems: Array<T>
  allItems: Array<T>
  allJsxNode: Array<{ id: string; reactNode: React.ReactNode }>
  isEdit: boolean
  onChange: (selectedVals: Array<T>) => void
  meta: TItemSelectorMeta
}

const ItemSelector = <T extends WithId>({
  selectedItems,
  allItems,
  allJsxNode,
  isEdit,
  onChange,
  meta,
}: ItemSelectorProps<T>): React.ReactElement => {
  // console.log('ItemSelector { selectedItems, allItems, allJsxNode }', { selectedItems, allItems, allJsxNode })

  const [t] = useTranslation()
  // card selector state:
  const [selectedTracker, setSelectedTracker] = useState<Array<T>>(selectedItems)
  const [unselectedTracker, setUnselectedTracker] = useState<Array<T>>([])

  useEffect(() => {
    setUnselectedTracker(allItems.filter(ai => !selectedItems.find(si => si.id === ai.id)))
  }, [selectedItems, allItems])

  const toggleItemSelected = (id: string) => {
    // console.log('Item Selector toggledItem:', id)
    const inUnselectedIndex = unselectedTracker.findIndex(si => si.id === id)
    if (inUnselectedIndex !== -1) {
      // move from unselected to selected
      const splicedT = unselectedTracker.splice(inUnselectedIndex, 1)
      selectedTracker.push(splicedT[0])
    } else {
      const inSelectedIndex = selectedTracker.findIndex(si => si.id === id)
      if (inSelectedIndex !== -1) {
        // move from selected to unselected
        const splicedT = selectedTracker.splice(inSelectedIndex, 1)
        unselectedTracker.push(splicedT[0])
      }
    }

    setUnselectedTracker([...unselectedTracker])
    setSelectedTracker([...selectedTracker])

    onChange(selectedTracker)
  }

  const toggleAll = (selectAll: boolean): void => {
    const sourceArray = selectAll ? [...unselectedTracker] : [...selectedTracker]
    for (const item of sourceArray) {
      toggleItemSelected(item.id)
    }
  }

  return (
    <>
      <span className={meta.selectedLabelClassName}>{meta.selectedItemsLabel}</span>
      <div className={meta.selectedContainerClassName}>
        {selectedTracker?.length > 0 ? (
          selectedTracker.map(st => {
            const children = allJsxNode.find(ajn => ajn.id === st.id)?.reactNode
            return (
              <div
                key={st.id}
                className={clsx('relative', isEdit && 'cursor-pointer')}
                onClick={() => isEdit && toggleItemSelected(st.id)}
              >
                {isEdit && (
                  <div className="absolute -top-1 -right-1 text-red-800 bg-white rounded-full text-2xl">
                    <RxCrossCircled />
                  </div>
                )}

                {children}
              </div>
            )
          })
        ) : (
          <>{meta.atLeastOneItemLabel}</>
        )}
      </div>
      {isEdit && (
        <>
          <div className="flex justify-between w-full">
            <span>
              <Button
                className="p-3 mx-3 text-lg"
                title={t('common.deselectAllItems')}
                onPress={() => toggleAll(false)}
                disabled={selectedTracker.length === 0}
              >
                <FaAnglesDown />
              </Button>
            </span>
            <span className={meta.unselectedLabelClassName}>{meta.unselectedItemsLabel}</span>
            <span>
              <Button
                className="p-3 mx-3 text-lg"
                title={t('common.selectAllItems')}
                onPress={() => toggleAll(true)}
                disabled={unselectedTracker.length === 0}
              >
                <FaAnglesUp />
              </Button>
            </span>
          </div>
          <div className={meta.unselectedContainerClassName}>
            {unselectedTracker?.length > 0 ? (
              unselectedTracker.map(ut => {
                const children = allJsxNode.find(ajn => ajn.id === ut.id)?.reactNode
                return (
                  <div
                    key={ut.id}
                    className="relative cursor-pointer"
                    onClick={() => isEdit && toggleItemSelected(ut.id)}
                  >
                    <div className="absolute -top-1 -right-1 text-green-800 bg-white rounded-full text-2xl">
                      <RxPlusCircled />
                    </div>
                    {children}
                  </div>
                )
              })
            ) : (
              <>{meta.allItemsSelectedLabel}</>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ItemSelector

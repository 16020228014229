import clsx from 'clsx'
import { ReactNode, useState } from 'react'
import { usePress } from 'react-aria'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

interface CollapsibleProps {
  defaultCollapsed: boolean
  leftSlot?: ReactNode
  middleSlot?: ReactNode
  rightSlot?: ReactNode
  children?: ReactNode
  className?: string
}

const Collapsible: React.FC<CollapsibleProps> = ({
  defaultCollapsed,
  leftSlot,
  middleSlot,
  rightSlot,
  children,
  className,
}: CollapsibleProps) => {
  const [currIsCollapsed, setCurrIsCollapsed] = useState<boolean>(defaultCollapsed)

  const { pressProps: toggleCollapsePressProps } = usePress({
    onPress: () => {
      const newState = !currIsCollapsed
      setCurrIsCollapsed(newState)
    },
  })

  return (
    <>
      <div
        {...toggleCollapsePressProps}
        className={clsx('w-full flex flex-row justify-between cursor-pointer', className)}
      >
        <div>{leftSlot}</div>
        <div>{middleSlot}</div>
        <div className="flex flex-row justify-end">
          <div>{rightSlot}</div>
          <div>{currIsCollapsed && <FaChevronUp className="text-2xl ml-4 text-shadow" />}</div>
          <div>{!currIsCollapsed && <FaChevronDown className="text-2xl ml-4 text-shadow" />}</div>
        </div>
      </div>
      {!currIsCollapsed && <div className="w-full">{children}</div>}
    </>
  )
}

export default Collapsible

import React, { Dispatch, SetStateAction, useState } from 'react'
import { FaBath, FaBed, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import clsx from 'clsx'
import { Blurhash } from 'react-blurhash'
import { useTranslation } from 'react-i18next'
import { TPropertyDto } from 'app-platform-shared-types'

interface PropertyCardProps {
  property: TPropertyDto
  style: 'compact' | 'preview' | 'full'
  onPropertyClick?: React.MouseEventHandler<HTMLDivElement>
}

type TCardLocalizedStrings = {
  [key: string]: string
}

const getCompactCard = (
  localizedStr: TCardLocalizedStrings,
  property: TPropertyDto,
  onPropertyClick?: React.MouseEventHandler<HTMLDivElement>,
) => (
  <div
    onClick={onPropertyClick}
    className={clsx('max-w-md', 'rounded', 'overflow-hidden', 'shadow-lg', 'flex', 'flex-row', {
      'hover:cursor-pointer': onPropertyClick,
    })}
  >
    {property.imgUrls?.length > 0 && <img className="w-[33%] py-6 pl-1" src={property.imgUrls[0]} alt="Property" />}
    <div className="w-[67%] px-6 py-6">
      <div className="font-bold text-xl mb-2 text-accent">{property.listPrice}</div>
      <div className="flex flex-col ">
        <div>
          <div
            className="my-3  text-gray-600 truncate"
            title={`${property.address1} ${property.address2 ?? ''}, ${property.city}`}
          >
            {`${property.address1} ${property.address2 ?? ''}, ${property.city}`}
          </div>
          <div className="flex justify-between">
            <span className="text-gray-700">{`${localizedStr.compactSqFt}`}</span>
            <span className="text-gray-900">{property.sqFt}</span>
          </div>
        </div>
        <div className="flex flex-row justify-between mt-5">
          <div className="text-gray-400 flex my-1" title={`${property.bedrooms} ${localizedStr.compactBedrooms}`}>
            <FaBed className="text-xl mx-1"></FaBed>&nbsp;<span>{property.bedrooms}</span>
          </div>
          <div className="text-gray-400 flex my-1" title={`${property.bathrooms} ${localizedStr.compactBathrooms}`}>
            <FaBath className="text-xl mx-1"></FaBath>&nbsp; <span>{property.bathrooms}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const scrollPreviewImage = (
  property: TPropertyDto,
  previewImageIndex: number,
  setPreviewImageIndex: Dispatch<SetStateAction<number>>,
  setPreviewImageLoaded: Dispatch<SetStateAction<boolean>>,
  increment: number,
) => {
  setPreviewImageLoaded(false)
  const newIndex = previewImageIndex + increment
  if (newIndex > 0) {
    setPreviewImageIndex(newIndex % property.imgUrls.length)
  } else {
    setPreviewImageIndex(property.imgUrls.length + increment)
  }
}

const getPreviewCard = (
  localizedStr: TCardLocalizedStrings,
  property: TPropertyDto,
  previewImageIndex: number,
  setPreviewImageIndex: Dispatch<SetStateAction<number>>,
  previewImageLoaded: boolean,
  setPreviewImageLoaded: Dispatch<SetStateAction<boolean>>,
) => (
  <>
    <div className="flex flex-nowrap justify-center relative select-none">
      <FaChevronLeft
        onClick={() => scrollPreviewImage(property, previewImageIndex, setPreviewImageIndex, setPreviewImageLoaded, -1)}
        className="absolute top-1/2 left-1 transform -translate-y-1/2 text-3xl p-1 bg-white text-gray-500 rounded-full hover:cursor-pointer hover:bg-gray-500 hover:text-white"
      />

      <div>
        {!previewImageLoaded && (
          <div className="border bg-white shadow-lg  h-[250px] w-[250px] md:h-[350px] md:w-[350px]">
            <Blurhash hash={property.blurHashImgs[previewImageIndex]} height={'100%'} width={'100%'} />
          </div>
        )}
        <div className="border bg-white shadow-lg">
          <img
            onLoad={() => setPreviewImageLoaded(true)}
            src={property.imgUrls[previewImageIndex]}
            alt="Preview of the property"
            className={clsx({ hidden: !previewImageLoaded }, 'h-[250px] md:h-[350px] v-auto')}
          />
        </div>
      </div>
      <FaChevronRight
        onClick={() => scrollPreviewImage(property, previewImageIndex, setPreviewImageIndex, setPreviewImageLoaded, 1)}
        className="absolute top-1/2 right-1 transform -translate-y-1/2 text-3xl p-1 bg-white text-gray-500 rounded-full hover:cursor-pointer hover:bg-gray-500 hover:text-white"
      />
    </div>

    <div className="min-h-full min-w-full mt-2">
      <div className="grid grid-cols-2">
        <div className="text-gray-600 text-right px-5">{`${localizedStr.previewAddress}`}</div>
        <div>{`${property.address1} ${property.address2 ?? ''}`}</div>
        <div className="text-gray-600 text-right px-5">{`${localizedStr.previewListPrice}`}</div>
        <div>{`${property.listPrice}`}</div>
        <div className="text-gray-600 text-right px-5">{`${localizedStr.previewNumBedrooms}`}</div>
        <div>{`${property.bedrooms}`}</div>
        <div className="text-gray-600 text-right px-5">{`${localizedStr.previewNumBathrooms}`}</div>
        <div>{`${property.bathrooms}`}</div>
        <div className="text-gray-600 text-right px-5">`${localizedStr.previewSquareFeet}`</div>
        <div>{`${property.sqFt}`}</div>
      </div>
    </div>
  </>
)

const PropertyCard: React.FC<PropertyCardProps> = ({ property, style, onPropertyClick }) => {
  const [previewImageIndex, setPreviewImageIndex] = useState<number>(0)
  const [previewImageLoaded, setPreviewImageLoaded] = useState<boolean>(false)
  const { t } = useTranslation()

  const cardLocalizedStrings: TCardLocalizedStrings = {
    previewAddress: t('propertyCard.preview.address'),
    previewListPrice: t('propertyCard.preview.listPrice'),
    previewNumBedrooms: t('propertyCard.preview.numBedrooms'),
    previewNumBathrooms: t('propertyCard.preview.numBathrooms'),
    previewSquareFeet: t('propertyCard.preview.squareFeet'),
    compactSqFt: t('propertyCard.compact.sqFt'),
    compactBedrooms: t('propertyCard.compact.bedrooms'),
    compactBathrooms: t('propertyCard.compact.bathrooms'),
  }

  return (
    <>
      {style === 'compact' && getCompactCard(cardLocalizedStrings, property, onPropertyClick)}
      {style === 'preview' &&
        getPreviewCard(
          cardLocalizedStrings,
          property,
          previewImageIndex,
          setPreviewImageIndex,
          previewImageLoaded,
          setPreviewImageLoaded,
        )}
    </>
  )
}

export { PropertyCard }
export type { PropertyCardProps }

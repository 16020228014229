import clsx from 'clsx'
import { useRef } from 'react'
import type { AriaTextFieldProps } from 'react-aria'
import { useTextField } from 'react-aria'

interface TextAreaProps extends AriaTextFieldProps {
  className?: string
  label?: string
  labelClassName?: string
  textAreaClassName?: string
  rows?: number
  value?: string
  errorMessage?: string
  onChange?: (val: string) => void
}

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const { label } = props
  const ref = useRef(null)
  const { labelProps, inputProps, errorMessageProps } = useTextField(
    {
      ...props,
      inputElementType: 'textarea',
    },
    ref,
  )

  return (
    <div className={clsx('flex flex-col', props.className)}>
      <label {...labelProps} className={clsx(props.labelClassName)}>
        {label}
      </label>
      <textarea
        {...inputProps}
        ref={ref}
        rows={props.rows}
        className={clsx(
          'rounded border-highlight focus:outline-none focus:border-accent focus:ring-2 focus:ring-transparent',
          props.textAreaClassName,
        )}
        value={props.value}
        onChange={(e: any) => props.onChange && props.onChange(e.target.value)}
      ></textarea>
      {props.errorMessage && (
        <div {...errorMessageProps} className="text-red-500 text-xs">
          {props.errorMessage}
        </div>
      )}
    </div>
  )
}

export default TextArea

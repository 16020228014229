import * as React from 'react'
import {
  Html,
  Img,
  Text,
  Container,
  Hr,
  Link,
  Head,
  Body,
  Section,
  Row,
  Column,
  Preview,
  Heading,
} from '@react-email/components'
import { formatCurrency } from '../../lib/helpers/utility.helper'
import { getLargestCmsAssetMeta } from '../../lib/helpers/csm-asset.helper'
import { orderBy } from 'lodash'
import { ClientConfig } from '../../config/client.config'
import i18next from 'i18next'
import { TPoolMemberDto, TTransactionDto, TPoolActivityDto, TCmsAsset } from 'app-platform-shared-types'

export interface ActivityNotificationEmailProps {
  excludeOuterHtml?: boolean
  poolName: string
  receiverPoolMember?: TPoolMemberDto
  receiverTransaction?: TTransactionDto
  activity: TPoolActivityDto
}

const ActivityNotificationEmail: React.FC<ActivityNotificationEmailProps> = (props: ActivityNotificationEmailProps) => {
  const { receiverPoolMember, receiverTransaction, activity } = props
  // console.log('ActivityNotificationEmail activity', activity)

  const sortedPms = orderBy(
    activity.poolMembers.map(pm => pm.poolMember),
    pm => pm.displayName,
  )

  const poolMembersExceptReceiver = sortedPms.filter(pm => pm.id !== receiverPoolMember?.id) ?? []

  const chunkSize = 3
  const chunkedPoolMembers: Array<Array<TPoolMemberDto>> = []

  for (let i = 0; i < poolMembersExceptReceiver.length; i += chunkSize) {
    const chunk = poolMembersExceptReceiver.slice(i, i + chunkSize)
    chunkedPoolMembers.push(chunk)
  }

  const isCredit = activity.type === 'ACCOUNT_CREDIT'

  const bodyCss = {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    padding: '5px',
  }

  const headerText = isCredit
    ? i18next.t('pool.activity.creditDebitNotification.titleCredit')
    : i18next.t('pool.activity.creditDebitNotification.titleDebit')

  const poolName = props.poolName

  const innerHtml = () => (
    <Container>
      <Section style={{ width: '100%' }}>
        <Row>
          <Column style={{ backgroundColor: '#F07E42', borderRadius: 3 }}>
            <Heading style={{ fontSize: 32, color: 'white', textAlign: 'center' }}>{poolName}</Heading>
          </Column>
        </Row>
      </Section>
      <Section
        style={{
          border: '1px solid rgb(0,0,0, 0.1)',
          borderRadius: '3px',
          overflow: 'hidden',
        }}
      >
        <Row>
          <Column>
            <Text style={{ fontSize: 26, fontWeight: 'bold', textAlign: 'center' }}>{headerText}</Text>
            <Text style={bodyCss}>
              {`${i18next.t('pool.activity.creditDebitNotification.hi')} ${receiverPoolMember?.displayName ?? ''},`}
            </Text>
            <Text style={bodyCss}>
              {isCredit
                ? `${i18next.t('pool.activity.creditDebitNotification.yourAccountCredited')} ${formatCurrency(activity.creditDebitAmt)}`
                : `${i18next.t('pool.activity.creditDebitNotification.yourAccountDebited')} ${formatCurrency(activity.creditDebitAmt)}`}
            </Text>
            <Text style={bodyCss}>
              {receiverTransaction &&
                `${i18next.t('pool.activity.creditDebitNotification.yourStartingBalance')} ${formatCurrency(receiverTransaction.initialBal)}. ${i18next.t('pool.activity.creditDebitNotification.yourEndingBalance')} ${formatCurrency(receiverTransaction.endBal)}.`}
            </Text>
            <Text style={bodyCss}>
              <>
                {`${i18next.t('pool.activity.creditDebitNotification.seeActivityDetails')}`}&nbsp;
                <Link href={`${ClientConfig.publicExternalAppUrl}/pools/${activity.poolId}/activity/${activity.id}`}>
                  {i18next.t('pool.activity.creditDebitNotification.activityDetailsLinkText')}
                </Link>
              </>
            </Text>
          </Column>
        </Row>
        {activity.cmsAsset?.cmsAssetsMeta?.length && (
          <>
            <Hr />
            {activity.cmsAsset.cmsAssetsMeta.map((a: TCmsAsset) => {
              const assetMeta = getLargestCmsAssetMeta(a)
              if (!assetMeta) {
                throw new Error('MISSING CMS ASSET')
              }
              return (
                <Row key={a.id}>
                  <Column style={{ padding: '2px 30px', width: '100%' }}>
                    <Link href={`${ClientConfig.publicCmsUrl}${assetMeta.url}`} target="_blank" rel="noreferrer">
                      <Img
                        src={`${ClientConfig.publicCmsUrl}${a.cmsAssetMetaFormats.thumbnail.url}`}
                        alt={a.cmsAssetMetaFormats.thumbnail.name}
                        height={a.cmsAssetMetaFormats.thumbnail.height}
                        width={a.cmsAssetMetaFormats.thumbnail.width}
                      />
                    </Link>
                  </Column>
                </Row>
              )
            })}
          </>
        )}
        {poolMembersExceptReceiver.length > 0 && (
          <>
            <Hr />
            <Row>
              <Column>
                <Text
                  style={{ ...bodyCss, fontWeight: 'bold', fontSize: 18 }}
                >{`${i18next.t('pool.activity.creditDebitNotification.yourFellowMembers')}`}</Text>
              </Column>
            </Row>
            {chunkedPoolMembers.map((chunk, i) => (
              <Row key={i}>
                {chunk.map(c => (
                  <Column key={c.id}>
                    <div
                      style={{
                        backgroundColor: '#B4C8D5',
                        border: '1px solid #708D9E',
                        borderRadius: 3,
                        margin: '10px',
                        padding: '3px',
                        display: 'flex',
                        width: '175px',
                        height: '76px',
                      }}
                    >
                      <Img
                        src={c.imgUrl}
                        width={50}
                        height={50}
                        style={{
                          width: '50px',
                          height: '50px',
                          borderRadius: '9999px',
                          backgroundColor: 'white',
                          fontSize: '1.125rem',
                          lineHeight: '2rem',
                          margin: '5px',
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 16,
                          height: '66px',
                          whiteSpace: 'normal',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        title={c.displayName}
                      >
                        {c.displayName}
                      </Text>
                    </div>
                  </Column>
                ))}
              </Row>
            ))}
          </>
        )}
      </Section>
    </Container>
  )

  return (
    <>
      {props.excludeOuterHtml ? (
        <>{innerHtml()}</>
      ) : (
        <Html lang="en">
          <Head />
          <Preview>
            {poolName} - {headerText}
          </Preview>
          <Body>{innerHtml()}</Body>
        </Html>
      )}
    </>
  )
}

export default ActivityNotificationEmail

import { TCmsAsset, TCmsAssetMeta } from 'app-platform-shared-types'

export const getLargestCmsAssetMeta = (asset: TCmsAsset): TCmsAssetMeta | undefined => {
  const result: TCmsAssetMeta | undefined =
    asset.cmsAssetMetaFormats.large ||
    asset.cmsAssetMetaFormats.medium ||
    asset.cmsAssetMetaFormats.small ||
    asset.cmsAssetMetaFormats.thumbnail

  return result
}

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslation from './locales/i18n/en/translation.json'
import frTranslation from './locales/i18n/fr/translation.json'
import ruTranslation from './locales/i18n/ru/translation.json'
import zhTranslation from './locales/i18n/zh/translation.json'

import { defaultLanguage, supportedLanguages } from '../../config/i18n.config'

const selectedLanguage = localStorage.getItem('selectedLanguage')

// import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  // .use(LanguageDetector) // Detect user language
  .use(initReactI18next)
  .init({
    lng: selectedLanguage?.length ? selectedLanguage : defaultLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: defaultLanguage,
    debug: false,
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      ru: {
        translation: ruTranslation,
      },
      zh: {
        translation: zhTranslation,
      },
    },
    supportedLngs: supportedLanguages.map(lang => lang.code),
    interpolation: {
      // React will escape output values, so we don't need
      // i18next to do it.
      escapeValue: false,
    },
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
  })

export default i18next

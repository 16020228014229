import { Routes, Route, BrowserRouter } from 'react-router-dom'
import appRoutes from './Routes'
import './style.css'
import { ClientConfig } from './config/client.config'
import RoutesTokenInterceptor from './RoutesTokenInterceptor'
import { Nav, Recaptcha } from './components'
import React, { useState } from 'react'
import { AppProvider } from './providers/Providers'
import { Locale } from './config/i18n.config'

const App: React.FC = () => {
  const [locale, setLocale] = useState<Locale>('en')

  return (
    <>
      <AppProvider locale={locale}>
        <BrowserRouter>
          <div className="flex flex-col overflow-y-hidden h-screen">
            <Nav setLocale={setLocale}></Nav>
            <div className="flex-grow overflow-y-auto">
              <Routes>
                <Route path="/auth" element={<RoutesTokenInterceptor />} />
                {appRoutes.map(
                  route => route.enabled && <Route key={route.key} path={route.path} element={<route.component />} />,
                )}
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </AppProvider>

      {ClientConfig.publicRecaptchaEnabled && <Recaptcha />}
    </>
  )
}

export default App
